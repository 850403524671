import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../state';
import { useNavigate, useLocation } from 'react-router-dom';
import { setTopicsCommunitiesRadioState } from '../../state/slices/tabsReducer';
import { TOPICS_COMMUNITIES_RADIO_STATES } from '../constants';
import CommunityListContainer from '../community/communityListContainer';
import { TopicsListContainer } from '../topic/topicsListContainer';

type TabState =
  (typeof TOPICS_COMMUNITIES_RADIO_STATES)[keyof typeof TOPICS_COMMUNITIES_RADIO_STATES];

export const HomepageContainer: React.FC<unknown> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const radioState = useAppSelector(
    (state) => state.tabs.topicsCommunitiesRadioState
  );

  useEffect(() => {
    const path = location.pathname;
    const newTab = path.includes(`/${TOPICS_COMMUNITIES_RADIO_STATES.TOPICS}`)
      ? TOPICS_COMMUNITIES_RADIO_STATES.TOPICS
      : TOPICS_COMMUNITIES_RADIO_STATES.COMMUNITIES;
    dispatch(setTopicsCommunitiesRadioState(newTab));
  }, [location.pathname, dispatch]);

  const handleTabChange = (newTab: TabState) => {
    dispatch(setTopicsCommunitiesRadioState(newTab));
    navigate(`/${newTab}`);
  };

  return (
    <div className="flex flex-col h-full w-full">
      <main className="flex flex-col justify-center w-full">
        <div className="tabs-row flex justify-center px-4">
          <div className="tabs max-w-3xl w-full">
            <button
              className={`py-4 ${radioState === TOPICS_COMMUNITIES_RADIO_STATES.COMMUNITIES ? 'active' : ''}`}
              onClick={() =>
                handleTabChange(TOPICS_COMMUNITIES_RADIO_STATES.COMMUNITIES)
              }
            >
              Communities
            </button>
            <button
              className={`py-4 ${radioState === TOPICS_COMMUNITIES_RADIO_STATES.TOPICS ? 'active' : ''}`}
              onClick={() =>
                handleTabChange(TOPICS_COMMUNITIES_RADIO_STATES.TOPICS)
              }
            >
              Topics
            </button>
          </div>
        </div>
      </main>

      <div className="flex w-full flex-row h-full">
        <div className="flex w-full flex-col">
          {radioState === TOPICS_COMMUNITIES_RADIO_STATES.TOPICS ? (
            <TopicsListContainer />
          ) : (
            <CommunityListContainer />
          )}
        </div>
      </div>
    </div>
  );
};
