import { AppState } from '../store';

export const selectTopics = (state: AppState) => state.topics.topics;
export const selectTopicsLoading = (state: AppState) => state.topics.loading;
export const selectTopicsError = (state: AppState) => state.topics.error;

export const selectTopicsSearchResults = (state: AppState) =>
  state.topics.searchResults;
export const selectTopicsSearchLoading = (state: AppState) =>
  state.topics.searchLoading;
export const selectTopicsSearchError = (state: AppState) =>
  state.topics.searchError;
