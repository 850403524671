import React, { useEffect, useState } from 'react';
import { TopicSummaryV3 } from '../topicsAndCommunities/topic-summary-v3';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { SearchPagination } from '../search/search-pagination';
import { useAppSelector } from '../../state';
import qs from 'qs';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loading } from '../../components/core';
import { useGetTopicsData } from '../topic-v2/hooks/useGetTopicsData';

interface TopicsContentProps {}

export const TopicsList: React.FC<TopicsContentProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const topicSortMethod = useAppSelector((state) => state.sort.topicSortMethod);
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const initialPage = query.p ? parseInt(query.p as string, 10) : 1;
  const [currentTopicPage, setCurrentTopicPage] = useState(initialPage);
  const { topics, loading, error } = useGetTopicsData({
    page: currentTopicPage,
    pageSize: 10,
    sortMethod: topicSortMethod
  });
  const viewMode = useAppSelector((state) => state.viewmode.mode);
  const isTripleView = viewMode === 'triple';

  const updatePage = (newPage: number) => {
    setCurrentTopicPage(newPage);
    const newSearch = qs.stringify({ p: newPage }, { addQueryPrefix: true });
    navigate({ pathname: location.pathname, search: newSearch });
  };

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const newPage = query.p ? parseInt(query.p as string, 10) : 1;
    setCurrentTopicPage(newPage);
  }, [location.search]);

  if (loading) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4 relative">
        <div className="flex w-full max-w-3xl">
          <Loading></Loading>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (topics && topics.page?.length === 0) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl">
          <p>No topics yet.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex flex-col items-center h-full w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl">
          <div className="flex flex-wrap w-full w-full-for-all">
            {topics && topics.page && topics.page.length > 0 && (
              <ResponsiveMasonry
                columnsCountBreakPoints={{
                  350: 1,
                  600: isTripleView ? 3 : 1,
                  900: isTripleView ? 3 : 1
                }}
              >
                <Masonry gutter="14px">
                  {topics.page.map((topic, idx) => (
                    <TopicSummaryV3 key={idx} topic={topic} />
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            )}
          </div>
        </div>
      </div>
      {topics && topics.numPages && currentTopicPage && (
        <div className="max-w-3xl mx-auto mt-8 w-full">
          <SearchPagination
            currentPage={currentTopicPage}
            lastPage={topics.numPages}
            onPageChange={updatePage}
          />
        </div>
      )}
    </div>
  );
};

export default TopicsList;
