import { ApiAuthorRef, ApiPaperLink } from '../../api/oipub-api';
import { RenderHTML } from '../../components/paper/render-html';

export interface Props {
  title: string;
  authors: ApiAuthorRef[];
  links: ApiPaperLink[];
  id: string | null | undefined;
}

// let showAuthorsCount = 2;

export const Furniturev2: React.FC<Props> = (props: Props) => {
  const { title } = props;
  // const renderedAuthors: JSX.Element[] = [];

  // function handleExternalLink() {
  //   analytics.events.clickPaperLink();
  // }

  // for (let author of authors.slice(0, showAuthorsCount)) {
  //   if (renderedAuthors.length < showAuthorsCount + 1) {
  //     renderedAuthors.push(
  //       /* TODO: Add links to author */
  //       <span
  //         className="author-list-item tablet:flex-no-wrap tablet:mb-0 flex flex-wrap mb-2 mr-1 text-gray-900 whitespace-no-wrap"
  //         key={author.name}
  //       >
  //         {author.name}
  //       </span>
  //     );
  //   }
  // }

  // const firtsLinkUrl = links[0]?.url ? links[0]?.url : "";
  // const getUrl = (apiPaperLink: ApiPaperLink) => {
  //   return apiPaperLink.url || "";
  // };

  return (
    <div className="max-w-2xl mb-3">
      <div className="items-baseline">
        <h1 className=" max-w-2xl mb-3 text-xl font-bold leading-snug">
          <RenderHTML htmlContent={title} />
        </h1>
        <div className="text-primary-500 text-sm flex">#Pa[{props.id}]</div>
        {/* <div className="tablet:ml-auto tablet:block tablet:mb-0 flex items-center flex-shrink-0 w-48 mb-6">
          <Button disabled={links.length === 0}>
            <span className="w-4 h-4 mr-2">
              <Eye />
            </span>
            <a onClick={handleExternalLink} href={firtsLinkUrl} target="_blank" rel="noreferrer">
              View Paper
            </a>
            <span className="w-2 h-2 ml-1">
              <ExternalLink />
            </span>{" "}
          </Button>
          {links.length > 1 &&
            links.slice(1).map((l) => (
              <a
                className="text-primary-500 hover:text-primary-700 tablet:mt-4 tablet:ml-0 flex items-start ml-4 whitespace-no-wrap transition-colors duration-200 cursor-pointer"
                href={getUrl(l)}
                key={l.url}
                onClick={handleExternalLink}
              >
                <span>View paper</span>
                <span className="w-2 h-2 ml-1">
                  <ExternalLink />
                </span>
              </a>
            ))}
        </div> */}
      </div>
    </div>
  );
};
