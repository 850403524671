import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Input, Button } from '../form';
import { Search } from '../../svgs';
import { Link } from 'react-router-dom';
import { Dropdown, Flex, Image } from '../../atoms';
import { texts } from '../../text';
import { ApiSearchType } from '../../api';

export interface Props {
  backToPageLink?: string;
  backToPageNum?: number;
  className?: string;
  onSearch(term: string, type: ApiSearchType): void;
  placeholder: string;
  value: string;
  setSelectedItem?(item: string): void;
  initialOption?: ApiSearchType;
}

const searchOptions = [
  {
    id: 1,
    name: ApiSearchType.Papers
  },
  {
    id: 2,
    name: ApiSearchType.Topics
  },
  {
    id: 3,
    name: ApiSearchType.Communities
  }
];

export const SearchField: React.FC<Props> = (props) => {
  const {
    backToPageLink,
    backToPageNum,
    className,
    onSearch,
    placeholder,
    value,
    initialOption
  } = props;
  const [text, setText] = useState<string>(value);
  const [type, setType] = useState(
    initialOption ? initialOption : searchOptions[0].name
  );

  useEffect(() => {
    setText(value);
  }, [value]);

  function handleKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      onSearch((e.target as HTMLInputElement).value, type);
    }
  }

  function handleSearch() {
    onSearch(text, type);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setText(e.target.value);
  }

  const onChangeDropDown: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const newSearchType = searchOptions.filter(
      (option) => String(option.id) === event.target.value
    )[0];
    if (newSearchType) {
      setType(newSearchType.name);
    }
  };

  const defaultValue = searchOptions.filter((option) => option.name === type)[0]
    .id;

  return (
    <div className={classNames('w-full max-w-3xl relative', className)}>
      <Input
        className="bg-primaryFaded hover:border-primary-300 hover:placeholder-gray-400 focus:bg-white focus:placeholder-gray-300 border-primary-200 placeholder-faded transition-all duration-200"
        style={{ maxWidth: '48rem', paddingRight: '8rem' }}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        placeholder={placeholder}
        value={text}
      />
      <div className={classNames('w-32 absolute top-0 mt-2 ml-2', className)}>
        <Flex
          direction="flex-row"
          className=" w-24 h-8 items-center justify-center border border-primaryDefault border-solid transition-colors duration-200 bg-white rounded"
        >
          <Dropdown
            className={
              'h-8 w-20 pl-4 text-primaryDefault text-sm z-10 justify-center items-center'
            }
            options={searchOptions}
            onChange={onChangeDropDown}
            backgroundColor="bg-transparent"
            defaultValue={defaultValue}
          />
          <Flex style={{ position: 'absolute', right: '1rem' }}>
            <Image
              style={{ width: '9px', height: '5px' }}
              className="relative pr"
              onClick={() => {}}
              src={'/arrow-down-icon.png'}
              title=""
              alt=""
            />
          </Flex>
        </Flex>
      </div>
      <div
        className="absolute right-0 flex items-center"
        style={{ top: '0.25rem', right: '0.25rem' }}
      >
        {backToPageNum && text === value && (
          <Link
            className="hover:text-primary-500 mr-4 text-xs text-gray-700 transition-colors duration-200 cursor-pointer"
            to={backToPageLink!}
          >
            {texts.returnToResult}
          </Link>
        )}

        <Button
          restyled
          onClick={handleSearch}
          className="h-10 px-4 py-2 opacity-50 bg-gradient-to-r from-primaryLight to-primaryDefault hover:opacity-100"
        >
          <span>{texts.search}</span>
          <span className="block w-4 h-4 ml-1">
            <Search />
          </span>
        </Button>
      </div>
    </div>
  );
};
