import { FC } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { ApiPaper } from '../../api/oipub-api';
import { SearchPagination } from '../../routes/search/search-pagination';
import { Previewv2 } from '../paper';
import { useAppSelector } from '../../state';
import { Loading } from '../core';
import { useGetPapersByCommunityUrlKeyData } from './hooks/useGetPapersByCommunityUrlKeyData';

interface DiscussionCommunityListProps {
  urlKey?: string;
  isTripleView: boolean;
  updatePage: (newPage: number) => void;
  currentDiscussionPage: number;
}

export const PapersCommunityList: FC<DiscussionCommunityListProps> = ({
  urlKey,
  isTripleView,
  updatePage,
  currentDiscussionPage
}) => {
  const papersSortMethod = useAppSelector(
    (state) => state.sort.paperCommunitySortMethod
  );
  const { papers, loading, error } = useGetPapersByCommunityUrlKeyData({
    page: currentDiscussionPage,
    pageSize: 10,
    urlKey: urlKey ?? '',
    sortMethod: papersSortMethod
  });

  if (loading) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4 relative">
        <div className="flex w-full max-w-3xl ">
          <Loading></Loading>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (papers && papers.page?.length === 0) {
    return (
      <div className="flex flex-col items-center h-auto w-full py-3 mb-2 px-4">
        <div className="flex w-full max-w-3xl">
          <p>No papers yet.</p>
        </div>
      </div>
    );
  }

  return (
    <div className=" flex flex-col  items-center h-auto  w-full py-3 mb-2 px-4">
      <div className="flex w-full flex  max-w-3xl ">
        <div className="flex flex-wrap  w-full">
          {papers && papers.page && papers.page.length > 0 && (
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                350: 1,
                600: isTripleView ? 2 : 1,
                900: isTripleView ? 3 : 1
              }}
              className="w-full"
            >
              <Masonry gutter="14px">
                {papers.page?.map((paper: ApiPaper, idx: number) => (
                  <Previewv2
                    key={`Previewv2${idx}`}
                    paper={paper}
                    showThreeItems={isTripleView}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
          )}
        </div>
      </div>
      {papers.numPages && currentDiscussionPage && (
        <div className="max-w-3xl mx-auto mt-8 w-full">
          <SearchPagination
            currentPage={currentDiscussionPage}
            lastPage={papers.numPages}
            numOfMobilPages={5}
            onPageChange={updatePage}
          />
        </div>
      )}
    </div>
  );
};
