import { useEffect, useState } from 'react';
import { CommentsectionsApi } from '../../../api';
import { useAppSelector } from '../../../state';
import { IComment } from '../../../types/comment.type';

export const useGetCommentsbyIdData = (
  commentSectionId: number | null | undefined
) => {
  const [commentsData, setCommentsData] = useState<IComment[] | null>(null);
  const commentsSortMethod = useAppSelector(
    (state) => state.sort.commentSortMethod
  );
  useEffect(() => {
    const fetchData = async () => {
      if (commentSectionId) {
        const comments = await CommentsectionsApi.getComments(
          commentSectionId,
          commentsSortMethod
        );
        setCommentsData(comments);
      }
    };
    fetchData();
  }, [commentSectionId, commentsSortMethod]);

  return { commentsData };
};
