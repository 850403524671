import { IComment } from '../../types/comment.type';
import oipubApi from '../api-instance';
import { ApiLoginResponse } from '../oipub-api';

export const CommentsectionsApi = {
  async getComments(
    commentSectionId: number,
    order?: string
  ): Promise<IComment[]> {
    const response = await oipubApi.v1CommentsectionsDetail(commentSectionId, {
      order
    });
    return response.data;
  },

  async postComment(
    commentSectionId: number,
    body: string,
    parentCommentId: number | null
  ): Promise<void> {
    const auth = JSON.parse(
      localStorage.getItem('auth') || ''
    ) as ApiLoginResponse;
    await oipubApi.v1CommentsectionsCreate(
      commentSectionId,
      { body, parentCommentId },
      { headers: { Authorization: `Bearer ${auth.jwt}` } }
    );
  }
};
