import { FC } from 'react';
import { usePapersDiscussionsTabs } from './hooks/usePapersDiscussionsTabs';

interface PapersDiscussionsTabsProps {
  hidePapersTab?: boolean;
  contentUrlKey: string;
}

export const PapersDiscussionsTabs: FC<PapersDiscussionsTabsProps> = ({
  hidePapersTab,
  contentUrlKey
}) => {
  const { handlePapersClick, handleDiscussionsClick, radioState } =
    usePapersDiscussionsTabs({
      contentUrlKey,
      hidePapersTab
    });

  return (
    <main className="flex flex-col justify-start w-full">
      <div className="tabs-row flex justify-start px-4">
        <div className="tabs max-w-3xl w-full m-auto">
          {!hidePapersTab && (
            <button
              className={`tab-button py-4 ${radioState === 'papers' ? 'active' : ''}`}
              onClick={handlePapersClick}
            >
              Papers
            </button>
          )}
          <button
            className={`py-4 ${radioState === 'discussions' ? 'active' : ''}`}
            onClick={handleDiscussionsClick}
          >
            Discussions
          </button>
        </div>
      </div>
    </main>
  );
};
