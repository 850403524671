export const accordionItems = [
  {
    title: 'How do I create a post?',
    content: (
      <div>
        Click “Create a New Post” on the top left and fill in your post details
        in the title and text sections. Select Topics and Communities you want
        to broadcast this post to. When you select a topic, your post will be
        broadcast to the topic itself, as well as all communities that follow
        this topic. This is useful when you want to broadcast to everyone
        interested in a certain subject. If you only want to discuss with a
        specific community, tag the community and it will go directly there.
      </div>
    )
  },
  {
    title: 'How do I create a community?',
    content: (
      <div>
        Click “Create a Community” on the top left. Fill in the community name
        and description, then select the topics that are relevant to this
        community. When done, click “Create Community“ and we will start adding
        and sorting all the papers and discussions related to the topics you
        selected. This can take some time to process, depending on the number
        and size of the topics you have selected. So check back and refresh the
        community in a short while. Soon enough, you&apos;ll have a custom
        community with all papers and discussions relevant to your interests!
      </div>
    )
  },
  {
    title:
      'What is the difference between the paper sort options (Top / Hot / Latest) in topics and communities?',
    content: (
      <div>
        Top - Use this sort to find the most important foundational papers in
        any subject. These papers have both: very high relevance, and very high
        impact, for the topic or community selected. Recency is not a factor
        here, though you will be able to filter papers by publication date.
        <br />
        <br />
        Hot - Use this sort option to keep up to date with a subject. This score
        balances time since publication and activity. The older a paper is, the
        lower it scores. Activity (e.g. citations, reads) pushes the score back
        up. In short, these papers are recent, impactful and relevant.
        <br />
        <br />
        Latest - Use this sort option to find the very latest papers in this
        topic or community. This sort only focuses on publication date.
      </div>
    )
  },
  {
    title:
      'What is the difference between the discussion sort options in topics and communities?',
    content: (
      <div>
        Most Comments - Use this to find items in this topic or community which
        had a lot of discussion. This is useful when you want to find papers or
        posts which received a lot of attention.
        <br />
        <br />
        Comment Activity - Use this to find the most active discussions. This
        sort considers comments over a recent time interval.
        <br />
        <br />
        Latest Discussion - Use this to find and respond to new discussions.
        This considers only the date of the original post itself or the first
        comment on a paper. The very first message that kicked off the
        discussion on that post or paper. This allows you to find new
        discussions even when they have no comments.
      </div>
    )
  }
];
