import { FC } from 'react';
import { Loading } from '../../components/core';
import { CommunityPage } from './communityPage';
import { selectCommunityLoading } from '../../state/selectors/communitySelector';
import { useAppSelector } from '../../state';
import { useGetCommunity } from './hooks/useGetCommunity';
import { useGetUserIsAdmin } from '../../components/user';
import { CommunityTopBar } from '../../components/core/topBars';

interface Props {}

export const CommunityContainer: FC<Props> = () => {
  useGetCommunity();
  useGetUserIsAdmin();
  const loading = useAppSelector(selectCommunityLoading);

  if (!loading) return <Loading />;

  return (
    <CommunityTopBar>
      <main className="page-content max-w-2/3">
        <CommunityPage />
      </main>
    </CommunityTopBar>
  );
};
