import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUserPost } from '../../types/userPost.type';
import { env } from '../../env';

interface GetUserPostArgs {
  id?: string;
}

export interface UpdateUserPostAdminArgs {
  id?: string;
  data: {
    title: string;
    markdown: string;
    topics: string[];
    communities: string[];
  };
}

export interface UpdateUserPostCreatorArgs {
  id?: string;
  data: {
    markdown: string;
    topics: string[];
    communities: string[];
  };
}

interface DeleteUserPostArgs {
  postId: number;
}

// Fetch User Post
export const getUserPost = createAsyncThunk<IUserPost, GetUserPostArgs>(
  'get/getUserPost',
  async ({ id }: GetUserPostArgs, { signal }) => {
    const response = await fetch(`${env.apiUrl}/api/v1/userpost/${id}`, {
      method: 'GET',
      signal
    });

    if (!response.ok) {
      throw new Error('Failed to fetch the post');
    }

    const res = await response.json();

    return res as IUserPost;
  }
);

// Update Post by Creator
export const updateUserPostByCreator = createAsyncThunk(
  'post/updateUserPostByCreator',
  async ({ id, data }: UpdateUserPostCreatorArgs, { signal }) => {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    const response = await fetch(
      `${env.apiUrl}/api/v1/userpost/${id}/by-creator`,
      {
        method: 'PUT',
        signal,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.jwt}`
        }
      }
    );

    if (!response.ok) {
      throw new Error('Something went wrong with updating the post');
    }

    const updatedPost = await response.json();
    return updatedPost;
  }
);

// Update Post by Admin
export const updateUserPostByAdmin = createAsyncThunk(
  'post/updateUserPostByAdmin',
  async ({ id, data }: UpdateUserPostAdminArgs, { signal }) => {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    const response = await fetch(
      `${env.apiUrl}/api/v1/userpost/${id}/by-super-user`,
      {
        method: 'PUT',
        signal,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.jwt}`
        }
      }
    );

    if (!response.ok) {
      throw new Error('Something went wrong with updating the post');
    }

    const updatedPost = await response.json();
    return updatedPost;
  }
);

// Delete Post
export const deleteUserPost = createAsyncThunk(
  'post/deleteUserPost',
  async ({ postId }: DeleteUserPostArgs, { signal }) => {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    const response = await fetch(
      `${env.apiUrl}/api/v1/userpost/${postId}/soft`,
      {
        method: 'DELETE',
        signal,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.jwt}`
        }
      }
    );

    if (!response.ok) {
      throw new Error('Failed to delete the post');
    }
    // const res = await response.json();
    return postId;
  }
);
