import { Link } from 'react-router-dom';
import pluralize from 'pluralize';

import { paperPublishedMonthYear } from '../../utils/month-formatter';
import { HasLinks } from '../../svgs';
import { thousandSeparator } from '../../utils/numbers-formatter';
import { analytics } from '../../analytics';
import { ApiPaper } from '../../api/oipub-api';
import { useGetCommentsbyIdData } from '../../api/dataLayer';

export interface Props {
  paper: ApiPaper;
  trackingLabel?: 'search' | 'feed' | 'topic';
}

export interface HeadingProps {
  children: React.ReactNode;
  underlined?: boolean;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  underlined
}: HeadingProps) => {
  let className =
    'tablet:inline-block text-primary-400 mr-2 tracking-wide uppercase';
  let style = {};
  if (underlined) {
    className += ' underline underline-offset-2';
    style = {
      textUnderlineOffset: '3px'
    };
  }
  return (
    <h5 className={className} style={style}>
      {children}
    </h5>
  );
};

export const Preview: React.FC<Props> = (props: Props) => {
  const { paper } = props;
  const { commentsData } = useGetCommentsbyIdData(paper.commentSectionId);

  function handleOpenPaper() {
    if (!!props.trackingLabel) {
      analytics.events.openPaper(props.trackingLabel);
    }
  }

  let renderedLinksAvailable: JSX.Element | null = null;
  if (paper.links && paper.links.length > 0) {
    renderedLinksAvailable = (
      <div
        className="text-primary-500 inline-block w-4 h-4 ml-2"
        title="Links available"
      >
        <HasLinks />
      </div>
    );
  }

  /* TODO Find way to show latest comment on hover/click of icon */
  // let renderedDiscussionBadge: JSX.Element | null = null;
  // if (commentsData && commentsData?.length > 0) {
  //   renderedDiscussionBadge = (
  //     <div className="text-primary-500 relative inline-block w-4 h-4 ml-2" title={`${commentsData.length} comments`}>
  //       <span
  //         className="font-regular absolute w-full h-full leading-4 text-center text-white"
  //         style={{ fontSize: "8px" }}
  //       >
  //         {commentsData.length}
  //       </span>
  //       <Comment />
  //     </div>
  //   );
  // }

  const renderedAuthors: JSX.Element[] = [];
  if (paper?.authors) {
    for (const author of paper.authors.slice(0, 2)) {
      if (renderedAuthors.length < 3) {
        renderedAuthors.push(
          <span key={renderedAuthors.length} className="author-list-item mr-1">
            {author.name}
          </span>
        );
      }
    }
  }

  let url = '/paper';
  if (paper?.id) {
    url += `/${paper.id}`;
  } else if (paper?.slug) {
    url += `/${paper.slug.yearMonth}/${paper.slug.slug}/${paper.slug.hash}`;
  }

  const numOfAuthors = paper.authors ? paper.authors.length : 0;

  return (
    <Link
      className="border-b-gray-400 last:border-b-0 
      hover:bg-primary-100 block w-full max-w-3xl mx-auto transition-colors duration-200 border-b"
      to={url}
      onClick={handleOpenPaper}
    >
      <div className="tablet:pt-8 tablet:px-4 px-2 pt-6 pb-4">
        <div className="mb-4">
          <h3 className="tablet:text-xl text-base font-bold leading-tight">
            {paper.title}
            {renderedLinksAvailable}
            {/* {renderedDiscussionBadge} */}
          </h3>
        </div>
        <div className="text-sm font-medium text-gray-700">
          <div className="tablet:flex tablet:mb-0 mb-2">
            <Heading>{pluralize('Author', numOfAuthors)}</Heading>
            <div className="tablet:flex-no-wrap flex flex-wrap">
              <div className="tablet:flex-no-wrap flex flex-wrap">
                {renderedAuthors}
              </div>
              {numOfAuthors > 2 && (
                <span className="text-gray-400">
                  and {thousandSeparator(numOfAuthors - 2)}{' '}
                  {pluralize('other', numOfAuthors - 2)}
                </span>
              )}
            </div>
          </div>
          <div className="flex">
            <Heading underlined>Comments</Heading>
            {commentsData?.length}
          </div>
          <div className="flex">
            <Heading underlined>Citations</Heading>
            {commentsData?.length}
          </div>
          <div className="flex">
            <Heading>Published</Heading>
            {paper.publishedYear && paper.publishedMonth && (
              <div className="flex">
                {paperPublishedMonthYear(
                  paper.publishedYear,
                  paper.publishedMonth
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};
