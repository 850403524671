import React, { useMemo, useState } from 'react';
import TimeAgo from 'react-timeago';

import { Button, Textarea } from '../../components/form';
import { useAppSelector } from '../../state';
import { analytics } from '../../analytics';
import moment from 'moment';
import { IComment } from '../../types/comment.type';
import { selectIsUserAdmin } from '../../state/selectors/userSelector';
import { formatDate } from '../../utils/userPostHelper';
import { MinusCircle } from '../../svgs/MinusCircle';
import { PlusCircleOrange } from '../../svgs/PlusCircleOrange';
import { DiscussionType } from '.';
import { ConfirmationModalWrapper } from '../modals';

export interface Props {
  comment: IComment;
  onReply(text: string, parentId: number): Promise<void>;
  onEdit: (commentId: number, newText: string) => Promise<void>;
  onDelete: (commentId: number) => Promise<void>;
  type: DiscussionType;
}

export const Comment: React.FC<Props> = ({
  comment,
  onReply,
  onEdit,
  onDelete,
  type
}) => {
  const isLoggedIn = useAppSelector((s) => !!s.login.user);
  const isUserAdmin = useAppSelector(selectIsUserAdmin);
  const user = useAppSelector((state) => state.login.user);
  const isUserCreator = useMemo(() => {
    return user && comment.createdByUser?.id
      ? Number(user.id) === Number(comment.createdByUser?.id)
      : false;
  }, [user, comment.createdByUser?.id]);
  const [collapsed, setCollapsed] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [text, setText] = useState('');
  const [saving, setSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(comment.text || '');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleDeleteClick = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleReply = async () => {
    setSaving(true);
    try {
      analytics.events.addComment(type);
      if (comment.id) {
        await onReply(text, comment.id);
        setText('');
        setShowReply(false);
      }
    } finally {
      setSaving(false);
    }
  };

  const handleEdit = async () => {
    if (comment.id && isEditing) {
      setSaving(true);
      try {
        await onEdit(comment.id, editText);
        setIsEditing(false);
      } finally {
        setSaving(false);
      }
    }
  };

  const handleConfirmDelete = async () => {
    if (comment.id) {
      await onDelete(comment.id);
      setIsConfirmationModalOpen(false);
    }
  };

  const toggleCollapsed = () => {
    setCollapsed((c) => !c);
  };

  return (
    <div className="relative max-w-2xl mb-6">
      <div
        className="hover:bg-primary-500 -left-5 absolute top-0 w-1 h-full transition-all duration-200 bg-gray-400 cursor-pointer"
        onClick={toggleCollapsed}
      />
      <div className="flex flex-wrap items-center mb-2 text-xs">
        {collapsed ? (
          // If the comment is currently collapsed, show an icon for expanding it
          <div
            className="text-primary-500 hover:text-primary-700 w-5 h-5 mr-2 transition-colors duration-200 cursor-pointer select-none"
            onClick={() => setCollapsed(false)}
            title="Expand comment"
          >
            <PlusCircleOrange />
          </div>
        ) : (
          // If the comment is currently expanded, show an icon for collapsing it
          <div
            className="text-primary-500 hover:text-primary-700 w-5 h-5 mr-2 transition-colors duration-200 cursor-pointer select-none"
            onClick={() => setCollapsed(true)}
            title="Collapse comment"
          >
            <MinusCircle />
          </div>
        )}
        <h5 className="mr-2 font-bold">
          {comment?.createdByUser?.displayName}
        </h5>
        <span>
          <TimeAgo date={moment.utc(comment.createdUtc).local().toDate()} />
        </span>
        {(comment.deletedUtc && comment.deletedByUser) ||
        comment.lastModifiedUtc ? (
          <div className="w-full opacity-50">
            {comment.deletedUtc && comment.deletedByUser ? (
              <>Deleted on:&nbsp;{formatDate(comment.deletedUtc)}</>
            ) : comment.lastModifiedUtc ? (
              <>Edited on:&nbsp;{formatDate(comment.lastModifiedUtc)}</>
            ) : null}
          </div>
        ) : null}
        {comment.comments && comment.comments.length > 0 && collapsed && (
          <div className="text-gray-600 w-full">
            <span>{comment.comments.length} </span>
            <span>replies</span>
          </div>
        )}
      </div>
      {!collapsed && (
        <div className="flex flex-col">
          {isEditing ? (
            <Textarea
              value={editText}
              onChange={(e) => setEditText(e.target.value)}
              disabled={saving}
              className="text-sm mb-2 outline-none border-none p-0 px-0 py-0 leading-relaxed"
            />
          ) : (
            <div className="mb-2 text-sm leading-relaxed">
              {comment.state === 2
                ? `This comment was deleted by ${
                    comment?.deletedByUser?.id !== comment?.createdByUser?.id
                      ? 'moderator'
                      : 'user'
                  }`
                : comment.text}
            </div>
          )}
          {comment.state !== 2 && (
            <div className="flex">
              {isLoggedIn && (
                <div className="flex items-center text-xs font-bold text-gray-600">
                  <span
                    className="last:mr-0 hover:text-primary-500 mr-2 transition-colors duration-200 cursor-pointer select-none"
                    onClick={() => setShowReply(true)}
                  >
                    Reply
                  </span>
                </div>
              )}
              {(isUserCreator || isUserAdmin) && (
                <div className="flex items-center text-xs font-bold text-gray-600">
                  <span
                    className="last:mr-0 hover:text-primary-500 mr-2 transition-colors duration-200 cursor-pointer select-none"
                    onClick={() => setIsEditing(!isEditing)}
                  >
                    Edit
                  </span>
                </div>
              )}
              {isEditing && (
                <div className="flex items-center text-xs font-bold text-gray-600">
                  <span
                    className="last:mr-0 hover:text-primary-500 mr-2 transition-colors duration-200 cursor-pointer select-none"
                    onClick={handleEdit}
                  >
                    Save
                  </span>
                </div>
              )}
              {(isUserCreator || isUserAdmin) && (
                <div className="flex items-center text-xs font-bold text-gray-600">
                  <span
                    className="last:mr-0 hover:text-primary-500 mr-2 transition-colors duration-200 cursor-pointer select-none"
                    onClick={handleDeleteClick}
                  >
                    Delete
                  </span>
                </div>
              )}
            </div>
          )}
          {showReply && (
            <div className=" p-4 mt-2">
              <h4 className="text-primary-700 relative z-20 mb-2 mr-10 text-sm font-bold tracking-wide uppercase">
                Reply to {comment?.createdByUser?.displayName}
              </h4>
              <Textarea
                disabled={saving}
                value={text}
                onChange={(e) => setText(e.target.value)}
                minRows={4}
                className="text-sm border-grayBorder mb-4 focus:outline-none focus:border-none"
                placeholder="Type your comment here"
              />
              <div className="flex justify-end">
                <Button
                  outline={true}
                  disabled={saving}
                  onClick={() => setShowReply(false)}
                  className="py-2 tablet:px-12 px-6"
                >
                  Cancel
                </Button>
                <Button
                  className="py-2 tablet:px-12 px-6 ml-2"
                  disabled={saving}
                  onClick={handleReply}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
          {comment.comments && !!comment.comments.length && (
            <div className="mt-4 ml-5">
              {comment.comments.map((c) => (
                <Comment
                  key={c.id}
                  comment={c}
                  onReply={onReply}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  type={type}
                />
              ))}
            </div>
          )}
          {isConfirmationModalOpen && (
            <ConfirmationModalWrapper
              isOpen={isConfirmationModalOpen}
              title="Confirm Deletion"
              message="Are you sure you want to delete this comment?"
              onConfirm={handleConfirmDelete}
              onClose={() => setIsConfirmationModalOpen(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};
