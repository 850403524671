import {
  COMMENT_SORT_METHODS,
  COMMUNITY_SORT_METHODS,
  CommentSortMethod,
  CommunitySortMethod,
  DISCUSSION_SORT_METHODS,
  DiscussionSortMethod,
  PAPER_COMMUNITY_SORT_METHODS,
  PAPER_TOPIC_SORT_METHODS,
  PaperCommunitySortMethod,
  PaperTopicSortMethod,
  TOPIC_SORT_METHODS,
  TopicSortMethod,
  USERPOSTS_SORT_METHODS,
  userPostsSortMethod
} from '../../utils/sortingConstants';
import {
  SET_COMMENT_SORT_METHOD,
  SET_COMMUNITY_SORT_METHOD,
  SET_DISCUSSION_SORT_METHOD,
  SET_PAPER_COMMUNITY_SORT_METHOD,
  SET_PAPER_TOPIC_SORT_METHOD,
  SET_TOPIC_SORT_METHOD,
  SET_USERPOSTS_SORT_METHOD,
  SortingActions
} from '../actions/sortingActions';

interface SortingState {
  discussionSortMethod: DiscussionSortMethod;
  paperCommunitySortMethod: PaperCommunitySortMethod;
  paperTopicSortMethod: PaperTopicSortMethod;
  topicSortMethod: TopicSortMethod;
  communitySortMethod: CommunitySortMethod;
  commentSortMethod: CommentSortMethod;
  userPostsSortMethod: userPostsSortMethod;
}

const initialSortingState: SortingState = {
  discussionSortMethod: DISCUSSION_SORT_METHODS.LAST_POST_DESC,
  paperCommunitySortMethod: PAPER_COMMUNITY_SORT_METHODS.COMMUNITY_PAPERS_TOP,
  paperTopicSortMethod: PAPER_TOPIC_SORT_METHODS.TOPIC_PAPERS_TOP,
  topicSortMethod: TOPIC_SORT_METHODS.NUM_RECENT_COMMENTS_DESC,
  communitySortMethod: COMMUNITY_SORT_METHODS.NUM_RECENT_COMMENTS_DESC,
  commentSortMethod: COMMENT_SORT_METHODS.CREATED_DESC,
  userPostsSortMethod: USERPOSTS_SORT_METHODS.LAST_COMMENT_DESC
};

const sortingReducer = (
  state: SortingState = initialSortingState,
  action: SortingActions
): SortingState => {
  switch (action.type) {
    case SET_DISCUSSION_SORT_METHOD:
      return {
        ...state,
        discussionSortMethod: action.payload
      };
    case SET_PAPER_COMMUNITY_SORT_METHOD:
      return {
        ...state,
        paperCommunitySortMethod: action.payload
      };
    case SET_PAPER_TOPIC_SORT_METHOD:
      return {
        ...state,
        paperTopicSortMethod: action.payload
      };
    case SET_TOPIC_SORT_METHOD:
      return {
        ...state,
        topicSortMethod: action.payload
      };
    case SET_COMMUNITY_SORT_METHOD:
      return {
        ...state,
        communitySortMethod: action.payload
      };
    case SET_COMMENT_SORT_METHOD:
      return {
        ...state,
        commentSortMethod: action.payload
      };
    case SET_USERPOSTS_SORT_METHOD:
      return {
        ...state,
        userPostsSortMethod: action.payload
      };
    default:
      return state;
  }
};

export default sortingReducer;
