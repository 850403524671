import { useState, useEffect } from 'react';
import { UserApi } from '../../../api/services/user-api';
import { useAppSelector } from '../../../state';
import { selectUserPost } from '../../../state/selectors/userPostSelector';

export const useGetPostUserDetails = () => {
  const userPostData = useAppSelector(selectUserPost);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserCreator, setIsUserCreator] = useState(false);
  const user = useAppSelector((state) => state.login.user);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await UserApi.myProfileDetail();
        if (response.data) {
          const profileData: IUserProfileIsAdmin = response.data;
          setIsUserAdmin(profileData.superUser);
          setIsUserCreator(
            String(profileData.id) === String(userPostData?.createdByUser?.id)
          );
        } else {
          throw new Error('Profile data is undefined');
        }
      } catch (error) {}
    };

    fetchUserProfile();
  }, [userPostData, user]);

  return { isUserAdmin, isUserCreator };
};
