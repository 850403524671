import { Link } from 'react-router-dom';

interface Props {}

export const NotFound: React.FC<Props> = () => {
  return (
    <>
      <div className="page-gap"></div>

      <div className="container">
        <h5 className="display-5 mb-4">Page Not Found</h5>

        <p className="lead">
          The page you are looking for can not be found. It has vanished into
          thin air.
        </p>

        <p className="lead">
          <Link to="/home">Click here</Link> to go to the home page.
        </p>
      </div>

      <div className="page-gap"></div>
    </>
  );
};
