import { LoginActions } from '../actions/loginActions';
import { analytics } from '../../analytics';
import { ApiLoginResponse, ApiLoginUser } from '../../api/oipub-api';

function loadInitialUser(): ApiLoginUser | null {
  const authJson = localStorage.getItem('auth');
  if (!!authJson) {
    const auth = JSON.parse(authJson) as ApiLoginResponse;
    if (auth.user && auth.expiry && new Date(auth.expiry) > new Date()) {
      const id = auth.user?.id ? String(auth.user.id) : undefined;
      analytics.setUserId(id);
      return auth.user;
    }
  }
  return null;
}

function loadInitialReferralCode(): string | null {
  return localStorage.getItem('referral_code');
}

export const initialState = {
  user: loadInitialUser(),
  referralCode: loadInitialReferralCode()
};

export type LoginState = typeof initialState;

export function loginReducer(
  state: LoginState = initialState,
  action: LoginActions
): LoginState {
  switch (action.type) {
    case 'LOGIN_SET_USER':
      analytics.setUserId(action.user?.id ? String(action.user.id) : undefined);
      return { ...state, user: action.user };
    case 'LOGIN_SET_REFERRAL_CODE':
      return { ...state, referralCode: action.code };
    default:
      return state;
  }
}
