import oipubApi from '../api-instance';
import { ApiLoginResponse, ApiUserPost } from '../oipub-api';

export const UserPostApi = {
  async get(id: number): Promise<ApiUserPost> {
    const response = await oipubApi.v1UserpostDetail(id);
    return response.data;
  },

  async create({
    title,
    markdown,
    topics,
    communities
  }: {
    title: string;
    markdown: string;
    topics: string[];
    communities: string[];
  }) {
    const auth = JSON.parse(
      localStorage.getItem('auth') || ''
    ) as ApiLoginResponse;
    const response = await oipubApi.v1UserpostsCreate(
      {
        title,
        markdown,
        topics,
        communities
      },
      {
        headers: { Authorization: `Bearer ${auth.jwt}` }
      }
    );
    return response;
  }
};
