import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchingStatus, useAppDispatch, useAppSelector } from '../../state';
import { ApiSearchType } from '../../api';
import { SearchActions } from '../../actions';
import { SearchPagination } from './search-pagination';
import { Previewv2 } from '../../components/paper';
import { ViewLine } from '../../svgs/ViewLine';
import { ViewThreeInLine } from '../../svgs/ViewThreeInLine';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { ApiCommonHub, ApiPaper } from '../../api/oipub-api';
import { Button } from '../../components/form/button';
import { HubPreviewv2 } from '../../components/hub';

export interface Props {
  query: string;
  page: number;
  type: ApiSearchType;
  status: SearchingStatus;
  papers: ApiPaper[] | null | undefined;
  hubs: ApiCommonHub[] | null | undefined;
  papersNumPages: number | undefined;
  hubsNumPages: number | undefined;
}
export const SearchContainer: React.FC<Props> = ({
  query,
  page,
  type,
  status,
  papers,
  hubs,
  papersNumPages,
  hubsNumPages
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const [showThreeItems, setShowThreeItems] = useState(false);
  const [results, setResults] = useState<React.ReactNode[]>([]);
  const [lastPage, setLastPage] = useState<number>(0);

  const handlePageChange = (newPage: number) => {
    SearchActions.changePage(
      dispatch,
      { query, newPage, type },
      location.pathname,
      navigate
    );
  };
  const searchState = useAppSelector((s) => s.search);

  useEffect(() => {
    if (type === ApiSearchType.Papers) {
      setResults(
        papers?.map((paper) => <Previewv2 key={paper.id} paper={paper} />) || []
      );
      setLastPage(papersNumPages ?? 0);
    } else {
      const hubComponents = hubs?.map((hub) => (
        <HubPreviewv2
          key={hub.refId}
          hub={hub}
          title={hub.name || ''}
          numPapers={1}
          numDiscussions={1}
          url={
            type === ApiSearchType.Topics
              ? `/topic/${hub.urlKey}`
              : `/community/${hub.urlKey}`
          }
        />
      ));
      setResults(hubComponents || []);
      setLastPage(hubsNumPages ?? 0);
    }
  }, [papers, hubs, papersNumPages, hubsNumPages, type]);

  const isOutdated =
    searchState.term !== query ||
    searchState.pageNum !== page ||
    searchState.searchType !== type;
  if (status === SearchingStatus.Timeout || status === SearchingStatus.Error) {
    return (
      <div className="w-full max-w-3xl flex justify-center items-center flex-grow mx-auto">
        We couldn&apos;t process your search at this minute. We&apos;re actively
        working on improving this site and will iron out any issues. In the
        meantime, please search for something different.
      </div>
    );
  }
  if (status === SearchingStatus.Searching || isOutdated) {
    return (
      <div className="flex flex-grow items-center justify-center mt-5">
        <div className="text-gray-600">Loading results</div>
      </div>
    );
  }

  if (!results || results.length < 1) {
    return (
      <div className="w-full max-w-3xl flex justify-center items-center flex-grow mx-auto mt-6">
        No results found
      </div>
    );
  }

  return (
    <div className="search-results mt-2">
      <div className="flex w-full flex-col">
        <div className="w-full px-4">
          <div className="max-w-3xl flex w-full ml-auto mr-auto">
            <div
              className="flex flex-row justify-center 
            laptop:justify-start desktop:justify-start tablet:justify-start flex-wrap pt-5 "
            >
              <Button className="py-2 w-40 mr-1 mb-1">Relevance</Button>
              {/* <Button disabled outline={true} className="py-2 w-40 mr-1 mb-1">
                Top
              </Button>
              <Button disabled outline={true} className="py-2 w-40 mb-1">
                Latest
              </Button> */}
            </div>
          </div>
        </div>
        <div className="flex justify-center  w-full pt-0 mb-2 px-4">
          <div className="flex w-full  flex justify-end max-w-3xl">
            <div className="flex justify-end btnsOrder ">
              <button onClick={() => setShowThreeItems(false)}>
                <ViewLine />
              </button>
              <button onClick={() => setShowThreeItems(true)}>
                <ViewThreeInLine />
              </button>
            </div>
          </div>
        </div>
        <div className=" flex flex justify-center h-full  w-full py-3 mb-2 px-4">
          <div className="flex w-full flex  max-w-3xl ">
            <div className="flex flex-wrap  w-full">
              <ResponsiveMasonry
                columnsCountBreakPoints={{
                  350: 1,
                  600: showThreeItems ? 3 : 1,
                  900: showThreeItems ? 3 : 1
                }}
                className="w-full"
              >
                <Masonry gutter="14px">
                  {results?.map((result) => result)}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </div>
        </div>
      </div>
      {page && lastPage && (
        <div className="max-w-3xl mx-auto mt-8">
          <SearchPagination
            currentPage={page}
            lastPage={lastPage}
            numOfMobilPages={5}
            onPageChange={(page) => handlePageChange(page)}
          />
        </div>
      )}
    </div>
  );
};
