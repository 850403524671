import React from 'react';
import { Modal } from './modal';

interface ConfirmationModalWrapperProps {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmationModalWrapper: React.FC<
  ConfirmationModalWrapperProps
> = ({ isOpen, title, message, onConfirm, onClose }) => {
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal
      modalName="confirmationModal"
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      className="confirmation-modal main-page right-0"
    >
      <div>{message}</div>
      <div className="modal-footer">
        <div className="mt-6 flex justify-between items-center">
          <button
            className="btnSmall text-white bg-gradient-to-r text-small from-primaryLight to-primaryDefault px-4 py-2  gap-1 rounded opacity-100 hover:opacity-50 font-medium focus:outline-none w-40 text-center uppercase mr-2"
            onClick={handleConfirm}
          >
            Confirm
          </button>
          <button
            className="btnSmall text-white bg-gradient-to-r text-small from-primaryLight to-primaryDefault px-4 py-2  gap-1 rounded opacity-100 hover:opacity-50 font-medium focus:outline-none w-40 text-center uppercase"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
