import React from 'react';
import { PaperPageContainer } from './paper-container';
import { Loading } from '../../components/core';
import { useAppSelector } from '../../state';
import { useGetUserIsAdmin } from '../../components/user';
import { useGetPaper } from './hooks/useGetPaper';
import { PaperTopBar } from '../../components/core/topBars';

export interface Props {}

export const PaperPage: React.FC<Props> = () => {
  useGetUserIsAdmin();
  useGetPaper();

  const paper = useAppSelector((state) => state.paper.paper);

  if (!paper) return <Loading />;

  return (
    <PaperTopBar>
      <PaperPageContainer />
    </PaperTopBar>
  );
};
