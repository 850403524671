import axios from 'axios';
import { ApiLoginResponse } from './api/oipub-api';

export function startup() {
  axios.interceptors.request.use((req) => {
    const authJson = localStorage.getItem('auth');
    if (!!authJson) {
      const auth = JSON.parse(authJson) as ApiLoginResponse;
      if (auth?.expiry && new Date(auth.expiry) > new Date()) {
        req.headers['Authorization'] = `Bearer ${auth.jwt}`;
      }
    }
    return req;
  });
}
