import classNames from 'classnames';

interface Abstractv2Props {
  children: React.ReactNode;
}

export const Abstractv2: React.FC<Abstractv2Props> = (
  props: Abstractv2Props
) => {
  const { children } = props;

  return (
    <div
      className={classNames(
        ' relative max-w-3xl  transition-all duration-200 text-sm'
      )}
    >
      <h4 className="text-yellow relative z-20 mb-2 text-sm font-bold font-roboto tracking-wide uppercase">
        Abstract
      </h4>
      {children}
    </div>
  );
};
