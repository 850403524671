import React from 'react';
import classNames from 'classnames';

interface TooltipProps {
  className?: string;
  text: React.ReactNode;
  children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> = ({
  className,
  text,
  children
}) => {
  return (
    <div className={classNames('tooltip-container', className)}>
      {children}
      <div className="tooltip-text">{text}</div>
    </div>
  );
};
