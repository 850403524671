import { FC } from 'react';
import { Discussion } from '.';
import { Loading } from '../core';
import { useCommentsUserPost } from './hooks/useCommentsUserPost';
import { useAppSelector } from '../../state';
import {
  selectComments,
  selectCommentsLoading
} from '../../state/selectors/commentsSelector';

export const DiscussionContainer: FC = () => {
  const { handleReply, handleEdit, handleDelete } = useCommentsUserPost();
  const comments = useAppSelector(selectComments);
  const loading = useAppSelector(selectCommentsLoading);

  if (loading) {
    return (
      <div className="flex flex-col items-center h-auto max-w-3xl py-3 mb-2 px-4 relative">
        <div className="flex w-full max-w-3xl">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <Discussion
      type={'post'}
      comments={comments}
      onReply={handleReply}
      onEdit={handleEdit}
      onDelete={handleDelete}
    />
  );
};
