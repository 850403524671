import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IComment } from '../../types/comment.type';
import {
  deleteComment,
  editComment,
  getComments,
  getCommentsWithDeleted,
  postComment
} from '../actions/commentsActions';

interface CommentsState {
  comments: IComment[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: CommentsState = {
  comments: null,
  loading: false,
  error: null
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    clearComments: (state) => {
      state.comments = null;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getComments.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getComments.fulfilled,
        (state, action: PayloadAction<IComment[]>) => {
          state.comments = action.payload;
          state.loading = false;
        }
      )
      .addCase(getComments.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch comments';
        state.loading = false;
      })
      .addCase(getCommentsWithDeleted.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getCommentsWithDeleted.fulfilled,
        (state, action: PayloadAction<IComment[]>) => {
          state.comments = action.payload;
          state.loading = false;
        }
      )
      .addCase(getCommentsWithDeleted.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch comments';
        state.loading = false;
      })
      .addCase(postComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(postComment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postComment.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to post comment';
        state.loading = false;
      })
      .addCase(editComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(editComment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(editComment.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to edit comment';
        state.loading = false;
      })
      .addCase(deleteComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteComment.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to delete comment';
        state.loading = false;
      });
  }
});

export const { clearComments } = commentsSlice.actions;
export default commentsSlice.reducer;
