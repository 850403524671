import { useAppDispatch, useAppSelector } from '../../state';
import { setDiscussionSortMethod } from '../../state/actions/sortingActions';
import {
  DISCUSSION_SORT_METHODS,
  DiscussionSortMethod
} from '../../utils/sortingConstants';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { DiscussionCommunityList } from './discussions-community-list';
import { DiscussionTopicList } from './discussions-topic-list';
import { SortingButtons } from '../sortingButtons';
import { ViewModeToggle } from '../viewModeToggle';

interface DiscussionsContentProps {
  isCommunity?: boolean;
}

export const DiscussionsList: FC<DiscussionsContentProps> = ({
  isCommunity
}) => {
  const dispatch = useAppDispatch();
  const { urlKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const viewMode = useAppSelector((state) => state.viewmode.mode);
  const isTripleView = viewMode === 'triple';

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const initialPage = query.p ? parseInt(query.p as string, 10) : 1;
  const [currentDiscussionPage, setCurrentDiscussionPage] =
    useState(initialPage);
  const discussionSortMethod = useAppSelector(
    (state) => state.sort.discussionSortMethod
  );

  const updatePage = (newPage: number) => {
    setCurrentDiscussionPage(newPage);
    const newSearch = qs.stringify({ p: newPage }, { addQueryPrefix: true });
    navigate({ pathname: location.pathname, search: newSearch });
  };

  const handleSortingDiscussionsChange =
    (method: DiscussionSortMethod) => () => {
      dispatch(setDiscussionSortMethod(method));
    };

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const newPage = query.p ? parseInt(query.p as string, 10) : 1;
    setCurrentDiscussionPage(newPage);
  }, [location.search]);

  return (
    <div className="w-full h-full">
      <SortingButtons
        sortMethods={DISCUSSION_SORT_METHODS}
        currentSortMethod={discussionSortMethod}
        onSortMethodChange={(page) => handleSortingDiscussionsChange(page)}
      />
      <ViewModeToggle />
      {isCommunity ? (
        <DiscussionCommunityList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentDiscussionPage}
          discussionSortMethod={discussionSortMethod}
        />
      ) : (
        <DiscussionTopicList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentDiscussionPage}
          discussionSortMethod={discussionSortMethod}
        />
      )}
    </div>
  );
};
