import { createSlice } from '@reduxjs/toolkit';
import {
  PAPERS_DISCUSSIONS_RADIO_STATES,
  TOPICS_COMMUNITIES_RADIO_STATES
} from '../../routes/constants';

export interface RadioState {
  topicsCommunitiesRadioState: string;
  papersDiscussionsRadioState: string;
}

const initialState: RadioState = {
  topicsCommunitiesRadioState: TOPICS_COMMUNITIES_RADIO_STATES.COMMUNITIES,
  papersDiscussionsRadioState: PAPERS_DISCUSSIONS_RADIO_STATES.PAPERS
};

const radioStateSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setTopicsCommunitiesRadioState: (state, action) => {
      state.topicsCommunitiesRadioState = action.payload;
    },
    setPapersDiscussionsRadioState: (state, action) => {
      state.papersDiscussionsRadioState = action.payload;
    }
  }
});

// const radioStateReducer = (state: RadioState = initialState, action: RadioStateActions): RadioState => {
//   switch (action.type) {
//     case SET_TOPICS_COMMUNITIES_RADIO_STATE:
//       return {
//         ...state,
//         topicsCommunitiesRadioState: action.payload
//       };
//     case SET_PAPERS_DISCUSSIONS_RADIO_STATE:
//       return {
//         ...state,
//         papersDiscussionsRadioState: action.payload
//       };
//     default:
//       return state;
//   }
// };

export const {
  setTopicsCommunitiesRadioState,
  setPapersDiscussionsRadioState
} = radioStateSlice.actions;
export default radioStateSlice.reducer;
