import oipubApi from '../api-instance';
import { ApiLoginUser } from '../oipub-api';

export const AuthApi = {
  async login(email: string, password: string): Promise<ApiLoginUser> {
    const response = await oipubApi.v1AuthLoginCreate({ email, password });
    const loginResult = response.data;

    localStorage.setItem('auth', JSON.stringify(loginResult));
    if (loginResult.user) return loginResult.user;
    return {};
  },
  async forgotPassword(email: string, host?: string): Promise<void> {
    const response = await oipubApi.v1AuthForgotCreate({ email, host });
    return response.data;
  },
  async resetPassword(token: string, email: string, password: string) {
    const response = await oipubApi.v1AuthResetCreate({
      token,
      email,
      newPassword: password
    });
    return response.data;
  }
};
