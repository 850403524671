import { SearchingStatus } from '../types/search-models';
import { ApiSearchResults, ApiSearchType } from '../../api';
import {
  ApiCommonHubPaged,
  ApiFilterData,
  ApiPaperPaged
} from '../../api/oipub-api';

import { SearchActions } from '../actions/searchActions';

interface SearchState {
  term: string;
  pageNum: number;
  searchType: ApiSearchType;
  papers: ApiPaperPaged;
  hubs: ApiCommonHubPaged;
  status: SearchingStatus;
  results: ApiSearchResults | null;
  filterData: ApiFilterData;
}

export const initialState: SearchState = {
  term: '',
  pageNum: 1,
  searchType: ApiSearchType.Papers,
  results: null,
  status: SearchingStatus.None,
  papers: {},
  hubs: {},
  filterData: {}
};

export function searchReducer(
  state: SearchState = initialState,
  action: SearchActions
): SearchState {
  switch (action.type) {
    case 'SEARCH_SET_QUERY':
      return {
        ...state,
        term: action.term,
        pageNum: action.pageNum,
        searchType: action.searchType,
        filterData: action.filterData
      };
    case 'SEARCH_SET_STATUS':
      return { ...state, status: action.status };
    case 'SEARCH_SET_RESULTS':
      return { ...state, results: action.results };
    case 'SEARCH_SET_PAPERS':
      return { ...state, papers: action.papers };
    case 'SEARCH_SET_HUBS':
      return { ...state, hubs: action.hubs };

    default:
      return state;
  }
}
