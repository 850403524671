import { Furniturev2 } from './paper-furniturev2';
import { Abstractv2 } from './paper-abstractv2';
import { RenderHTML } from '../../components/paper/render-html';
import { useAppSelector } from '../../state';
import {
  selectComments,
  selectCommentsLoading
} from '../../state/selectors/commentsSelector';
import { Loading } from '../../components/core';
import { useCommentsPaper } from '../../components/discussion/hooks/useCommentsPaper';
import { Discussion } from '../../components/discussion';

export const PaperPageContainer: React.FC = () => {
  const { handleReply, handleDelete, handleEdit } = useCommentsPaper();

  const paper = useAppSelector((state) => state.paper.paper);
  const comments = useAppSelector(selectComments);
  const loading = useAppSelector(selectCommentsLoading);

  return (
    <div className="">
      <div className="paperBorder"></div>
      <main className="pt-8 pb-6 tablet:px-12 px-6">
        {paper && paper.title && paper.authors && paper.links && (
          <Furniturev2
            title={paper.title}
            authors={paper.authors}
            links={paper.links}
            id={paper.id}
          />
        )}
        {/* <div className="authorDate color-itemContent font-roboto text-ssm mb-3">
          Published by&nbsp;
          <span className="text-yellow opacity-1">{props.paper.publishingInfo?.publisher}</span>
        </div> */}
        <div className=" block mt-6 max-w-2xl">
          <div className=" tablet:order-first order-last">
            <Abstractv2>
              <RenderHTML htmlContent={paper?.abstract} />
            </Abstractv2>
          </div>
        </div>
      </main>
      {loading ? (
        <div className="flex flex-col items-center h-auto max-w-3xl py-3 mb-2 px-4 relative">
          <div className="flex w-full max-w-3xl">
            <Loading />
          </div>
        </div>
      ) : (
        <Discussion
          type="paper"
          comments={comments}
          onReply={handleReply}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};
