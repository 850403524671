type RenderHTMLProps = {
  htmlContent: string | null | undefined;
};

export const RenderHTML: React.FC<RenderHTMLProps> = ({
  htmlContent
}: RenderHTMLProps) => {
  const content = htmlContent ?? ''; // Fallback to an empty string if htmlContent is null or undefined
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};
