import { FC } from 'react';
import { ApiTopic } from '../../api/oipub-api';
import { PapersDiscussionsTabsTopic } from '../../components/papersDiscussionRadio';

interface Props {
  topic: ApiTopic;
}

export const TopicPage: FC<Props> = ({ topic }) => {
  return (
    <>
      <div className="w-full h-full">
        <div className="paperBorder"></div>
      </div>
      <PapersDiscussionsTabsTopic
        topicName={topic.name}
        urlKey={topic.urlKey}
      />
    </>
  );
};
