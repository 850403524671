import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ShareButton } from '../../components/share';
import { PapersCount } from '../../svgs/PapersCount';
import { DiscussionIcon } from '../../svgs/DiscussionIcon';
import { ICommunitySummary } from '../../types/community.type';
interface Props {
  community: ICommunitySummary;
}

export const CommunitySummaryV2: FC<Props> = ({ community }) => {
  const communityLink = useMemo(() => {
    return `/community/${community.urlKey}`;
  }, [community]);

  const shareUrl = useMemo(() => {
    const currentUrl = window.location.href;
    return currentUrl + communityLink;
  }, [communityLink]);

  const formatNumber = (number: number) =>
    new Intl.NumberFormat().format(number);

  // const paperLink = `/community/${community.name}/papers`;
  // const postsLink = `/community/${community.name}/posts`;
  // const handleClickCommunitySinglePage = useCallback(() => {
  //   navigate(communityLink);
  // }, [navigate, communityLink]);

  // const handleTopicSinglePagePapersTab = useCallback(() => {
  //   navigate(communityLink + '/papers');
  // }, [navigate, communityLink]);

  // const handleTopicSinglePageDiscussionTab = useCallback(() => {
  //   navigate(communityLink + '/discussions');
  // }, [navigate, communityLink]);

  return (
    <Link to={communityLink} className="item cursor-pointer w-full">
      <div className="itemTitle font-bold flex mb-3 cursor-pointer">
        <div
          className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight"
          // onClick={handleClickCommunitySinglePage}
        >
          {community.name}
        </div>
      </div>
      {/* <div className="authorDate color-itemContent font-roboto text-ssm mb-3">
        6 hours ago by&nbsp;
        <a href="#" className="text-yellow opacity-1 hover:opacity-75">
          Robert Bianchi
        </a>
      </div> */}
      <div className="itemContent color-itemContent text-itemContent mb-5">
        {community.description}
      </div>
      <hr />
      <div className="itemActions flex justify-between items-center text-black mt-4">
        <div className="itemActionPaperCount">
          <div
            // onClick={handleTopicSinglePagePapersTab}
            className="flex items-center"
          >
            <PapersCount className="flex-shrink-0" />
            <span className="text-half ml-1">
              {formatNumber(community.papersCount || 0)}
            </span>
          </div>
        </div>
        <div className="itemActionComments">
          <div
            // onClick={handleTopicSinglePageDiscussionTab}
            className="flex items-center"
          >
            <DiscussionIcon className="flex-shrink-0" />
            <span className="text-half w-full ml-1">
              {formatNumber(community.postsCount || 0)}
            </span>
          </div>
        </div>
        <div className="itemActionShare">
          <div className="flex items-center">
            <ShareButton url={shareUrl} />
          </div>
        </div>
      </div>
    </Link>
  );
};
