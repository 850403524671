import { createSlice } from '@reduxjs/toolkit';
import { getIsUserAdmin } from '../actions/userActions';

interface IUserState {
  profile: IUserProfileIsAdmin | null;
  loading: boolean;
  error: string | null;
}

const initialState: IUserState = {
  profile: null,
  loading: false,
  error: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      state.profile = null;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIsUserAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getIsUserAdmin.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.loading = false;
      })
      .addCase(getIsUserAdmin.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch user profile';
        state.loading = false;
      });
  }
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
