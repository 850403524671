import { useAppDispatch, useAppSelector } from '../../state';
import {
  setPaperCommunitySortMethod,
  setPaperTopicSortMethod
} from '../../state/actions/sortingActions';
import {
  PAPER_COMMUNITY_SORT_METHODS,
  PAPER_TOPIC_SORT_METHODS,
  PaperCommunitySortMethod,
  PaperTopicSortMethod
} from '../../utils/sortingConstants';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { PapersCommunityList } from './papers-community-list';
import { PapersTopicList } from './papers-topic-list';
import { SortingButtons } from '../sortingButtons';
import { ViewModeToggle } from '../viewModeToggle';

interface PapersContentProps {
  isCommunity?: boolean;
}

export const PapersList: FC<PapersContentProps> = ({ isCommunity }) => {
  const dispatch = useAppDispatch();
  const { urlKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const viewMode = useAppSelector((state) => state.viewmode.mode);
  const isTripleView = viewMode === 'triple';

  const papersSortMethod = useAppSelector((state) =>
    isCommunity
      ? state.sort.paperCommunitySortMethod
      : state.sort.paperTopicSortMethod
  );

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const initialPage = query.p ? parseInt(query.p as string, 10) : 1;
  const [currentPaperPage, setCurrentPaperPage] = useState(initialPage);

  const updatePage = (newPage: number) => {
    setCurrentPaperPage(newPage);
    const newSearch = qs.stringify({ p: newPage }, { addQueryPrefix: true });
    navigate({ pathname: location.pathname, search: newSearch });
  };

  const handleSortingPapersChange =
    (method: PaperCommunitySortMethod | PaperTopicSortMethod) => () => {
      if (isCommunity) {
        dispatch(
          setPaperCommunitySortMethod(method as PaperCommunitySortMethod)
        );
      } else {
        dispatch(setPaperTopicSortMethod(method as PaperTopicSortMethod));
      }
    };

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const newPage = query.p ? parseInt(query.p as string, 10) : 1;
    setCurrentPaperPage(newPage);
  }, [location.search]);

  return (
    <div className="w-full h-full">
      <SortingButtons
        sortMethods={
          isCommunity ? PAPER_COMMUNITY_SORT_METHODS : PAPER_TOPIC_SORT_METHODS
        }
        currentSortMethod={papersSortMethod}
        onSortMethodChange={(page) => handleSortingPapersChange(page)}
      />
      <ViewModeToggle />
      {isCommunity ? (
        <PapersCommunityList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentPaperPage}
        />
      ) : (
        <PapersTopicList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentPaperPage}
        />
      )}
    </div>
  );
};
