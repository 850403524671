import { Flex } from '../../atoms/';
import { Tag } from '../../types/topic.type';

interface Props {
  elements: Tag[];
  hidden: boolean;
  selectElement: (elem: string) => void;
}
export const InputDropDownList = (props: Props) => {
  const { elements, hidden, selectElement } = props;

  return (
    <Flex className={'bg-primaryFaded text-black pl-2 justify-start text-xs'}>
      {!hidden &&
        elements &&
        elements.map((element) => {
          return (
            <div key={element.urlKey}>
              <button
                type="button"
                key={element.urlKey}
                onClick={() => selectElement(element.urlKey)}
                className="hover:bg-primary-300 w-full text-left cursor-pointer"
              >
                {element.name}
              </button>
            </div>
          );
        })}
    </Flex>
  );
};
