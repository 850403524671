import React, { useEffect, useState } from 'react';
import { SearchContainer } from './search-container';
import { useAppDispatch, useAppSelector } from '../../state';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { ApiSearchType } from '../../api';
import { analytics } from '../../analytics';
import { SearchActions } from '../../actions';
import { ORDER_DIRECTION } from '../constants';
import { useGetUserIsAdmin } from '../../components/user';
import { SearchTopBar } from '../../components/core/topBars';

export interface Props {}
export const Search: React.FC<Props> = () => {
  useGetUserIsAdmin();
  const dispatch = useAppDispatch();
  const searchState = useAppSelector((s) => s.search);
  const filterData = useAppSelector((s) => s.filter.filterData);
  const status = useAppSelector((s) => s.search.status);
  const papers = useAppSelector((s) => s.search.papers);
  const hubs = useAppSelector((s) => s.search.hubs);
  const location = useLocation();
  const navigate = useNavigate();
  const queryString = qs.parse(location.search.substring(1));
  const query = queryString['q'] as string | '';
  const page = parseInt(queryString['p'] as string) || 1;
  const type = (queryString['t'] as ApiSearchType) || ApiSearchType.Papers;
  const [orderDirection, setOrderDirection] = useState(ORDER_DIRECTION.ASCEND);

  const changeOrder = () => {
    if (orderDirection === ORDER_DIRECTION.ASCEND) {
      setOrderDirection(ORDER_DIRECTION.DESCEND);
    } else {
      setOrderDirection(ORDER_DIRECTION.ASCEND);
    }
  };

  useEffect(() => {
    if (!query) {
      navigate('/');
      return;
    }
    analytics.events.search(type);
    SearchActions.search(dispatch, query, page, type, filterData);
  }, [filterData, query, page, type, dispatch, navigate]);

  return (
    <SearchTopBar
      setOrderDirection={changeOrder}
      // onChange={onFilter}
      orderDirection={orderDirection}
      searchStateType={searchState.searchType}
    >
      <SearchContainer
        query={query}
        page={page}
        type={type}
        status={status}
        papers={papers.page}
        hubs={hubs.page}
        papersNumPages={papers.numPages}
        hubsNumPages={hubs.numPages}
      />
    </SearchTopBar>
  );
};
