import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ApiTopicSummary } from '../../api/oipub-api';
import { ShareButton } from '../../components/share';
import { PapersCount } from '../../svgs/PapersCount';
import { DiscussionIcon } from '../../svgs/DiscussionIcon';

interface Props {
  topic: ApiTopicSummary;
  handleClick?: () => void;
}

export const TopicSummaryV3: FC<Props> = ({ topic }) => {
  const topicLink = useMemo(() => {
    return `/topic/${topic.urlKey}`;
  }, [topic]);

  const shareUrl = useMemo(() => {
    const currentUrl = window.location.href;
    return currentUrl + topicLink;
  }, [topicLink]);

  const formatNumber = (number: number) =>
    new Intl.NumberFormat().format(number);

  // const handleClicktopicSinglePage = useCallback(() => {
  //   navigate(topicLink);
  // }, [navigate, topicLink]);

  // const handleTopicSinglePagePapersTab = useCallback(() => {
  //   navigate(topicLink + '#papers');
  // }, [navigate, topicLink]);

  // const handleTopicSinglePageDiscussionTab = useCallback(() => {
  //   navigate(topicLink + '#discussions');
  // }, [navigate, topicLink]);

  return (
    <Link to={topicLink} className="item cursor-pointer w-full">
      <div className="itemTitle font-bold flex mb-3 cursor-pointer">
        <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight">
          {topic.name}
        </div>
      </div>
      {/* <div className="authorDate color-itemContent font-roboto text-ssm mb-3">
        8 hours ago by&nbsp;
        <a href="#" className="text-yellow opacity-1 hover:opacity-75">
          John Balham
        </a>
      </div> */}
      {/* <div className="itemContent color-itemContent text-itemContent mb-5">{topic.discussions}</div> */}
      <hr></hr>
      <div className="itemActions flex justify-between align-center text-black mt-4">
        <div className="itemActionPaperCount">
          <div
            // onClick={handleTopicSinglePagePapersTab}
            className="flex items-center"
          >
            <PapersCount />
            <span className="text-half ml-1">
              {formatNumber(topic.papersCount || 0)}
            </span>
          </div>
        </div>
        <div className="itemActionDiscussions">
          <div
            // onClick={handleTopicSinglePageDiscussionTab}
            className="flex items-center"
          >
            <DiscussionIcon />
            <span className="text-half ml-1">
              {formatNumber(topic.postsCount || 0)}
            </span>
          </div>
        </div>
        <div className="itemActionShare">
          <div className="flex items-center">
            <ShareButton url={shareUrl} />
          </div>
        </div>
      </div>
    </Link>
  );
};
