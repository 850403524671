import { FC } from 'react';
import { Loading } from '../../components/core';
import { TopicPage } from './topicPage';
import { useParams } from 'react-router-dom';
import { useGetUserIsAdmin } from '../../components/user';
import { TopBar } from '../../components/core/topBars';
import { useGetTopicData } from './hooks/useGetTopicData';

interface Props {}

export const TopicContainer: FC<Props> = () => {
  const { urlKey } = useParams();
  const { topic } = useGetTopicData({ urlKey: urlKey || '' });
  useGetUserIsAdmin();
  if (!topic) return <Loading />;
  return (
    <TopBar>
      <main className="page-content max-w-2/3">
        <TopicPage topic={topic} />
      </main>
    </TopBar>
  );
};
