import qs from 'qs';

import { ApiSearchType, LibraryApi } from '../api';
import { AppDispatch, SearchingStatus } from '../state';
import { ApiFilterData } from '../api/oipub-api';
import type { NavigateFunction } from 'react-router-dom';

interface SearchParams {
  query: string;
  newPage: number;
  type: ApiSearchType;
}

export const SearchActions = {
  getSearchUrl(term: string, type: ApiSearchType, page: number) {
    const params = qs.stringify({
      q: term,
      t: type === ApiSearchType.Papers ? undefined : type,
      p: !page || page <= 1 ? undefined : page
    });
    return `/search?${params}`;
  },

  async changePage(
    dispatch: AppDispatch,
    params: SearchParams,
    pathname: string,
    navigate: NavigateFunction
  ) {
    const { query, newPage, type } = params;
    try {
      await this.search(dispatch, query, newPage, type, {}); // Use the correct type for filterData
      const queryString = qs.stringify({
        q: query,
        t: type === ApiSearchType.Papers ? undefined : type,
        p: !newPage || newPage <= 1 ? undefined : newPage
      });
      navigate({
        pathname: pathname,
        search: `?${queryString}`
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching search results:', error);
    }
  },

  async search(
    dispatch: AppDispatch,
    term: string,
    pageNum: number,
    searchType: ApiSearchType,
    filterData: ApiFilterData
  ) {
    const timeout = setTimeout(() => {
      dispatch({ type: 'SEARCH_SET_STATUS', status: SearchingStatus.Timeout });
    }, 15000);

    dispatch({ type: 'SEARCH_SET_STATUS', status: SearchingStatus.Searching });
    try {
      dispatch({
        type: 'SEARCH_SET_QUERY',
        term,
        pageNum,
        searchType,
        filterData
      });
      dispatch({ type: 'SEARCH_SET_RESULTS', results: null });

      // Run search
      if (searchType === ApiSearchType.Papers) {
        const papers = await LibraryApi.searchPapers(term, pageNum);
        dispatch({ type: 'SEARCH_SET_PAPERS', papers });
      } else {
        const hubs = await LibraryApi.searchTopicsCommunities(
          term,
          pageNum,
          searchType
        );
        dispatch({ type: 'SEARCH_SET_HUBS', hubs });
      }

      dispatch({ type: 'SEARCH_SET_STATUS', status: SearchingStatus.Ready });
    } catch (ex) {
      // TODO AJS: show search errors
      dispatch({ type: 'SEARCH_SET_RESULTS', results: null });
      dispatch({ type: 'SEARCH_SET_STATUS', status: SearchingStatus.Error });
    } finally {
      clearTimeout(timeout);
    }
  },

  async clear(dispatch: AppDispatch) {
    dispatch({
      type: 'SEARCH_SET_QUERY',
      term: '',
      pageNum: 1,
      searchType: ApiSearchType.Papers,
      filterData: {}
    });
    dispatch({ type: 'SEARCH_SET_STATUS', status: SearchingStatus.None });
    dispatch({ type: 'SEARCH_SET_RESULTS', results: null });
  }
};
