import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../state';
import { LibraryActions, SearchActions } from '../../actions';
import { ApiSearchType } from '../../api';
import qs from 'qs';
import { SearchField3 } from '../../components/core/search-field-3';

export interface Props {
  className?: string;
  placeholder?: string;
}

export const SearchFieldContainer: React.FC<Props> = () => {
  const location = useLocation();
  const counts = useAppSelector((s) => s.library.counts);
  const searchState = useAppSelector((s) => s.search);
  const filterState = useAppSelector((state) => state.filter.filterData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!counts) {
      LibraryActions.loadCounts(dispatch);
    }
  });

  function handleSearch(q: string, searchType: ApiSearchType) {
    if (!q) {
      dispatch({
        type: 'SEARCH_SET_QUERY',
        term: '',
        pageNum: 1,
        searchType: ApiSearchType.Papers,
        filterData: filterState
      });
      navigate('/');
    } else {
      //     SearchActions.changePage(dispatch, { query: q, newPage: 1, type: searchType }, history);
      const url = SearchActions.getSearchUrl(q, searchType, 1);
      navigate(url);
    }
  }

  const queryString = qs.parse(location.search.substring(1));
  const initialOption =
    (queryString['t'] as ApiSearchType) || ApiSearchType.Papers;

  return (
    <>
      <SearchField3
        initialText={searchState.term}
        initialType={initialOption}
        onSearch={handleSearch}
        counts={counts ? counts : undefined}
      />
    </>
  );
};
