import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getPapersByTopicUrlKey,
  getPapersByCommunityUrlKey
} from '../actions/papersActions';
import { IPaperPaged } from '../../types/papers.type';

interface PapersState {
  papers: IPaperPaged;
  loading: boolean;
  error: string | null;
}

const initialState: PapersState = {
  papers: {
    count: 0,
    pageSize: 0,
    numPages: 0,
    pageNum: 0,
    page: []
  },
  loading: false,
  error: null
};

const papersSlice = createSlice({
  name: 'papers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPapersByTopicUrlKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPapersByTopicUrlKey.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.papers = action.payload;
          state.loading = false;
        }
      )
      .addCase(getPapersByTopicUrlKey.rejected, (state, action) => {
        state.error =
          action.error.message || 'Failed to fetch papers by topic URL key.';
        state.loading = false;
      })
      .addCase(getPapersByCommunityUrlKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPapersByCommunityUrlKey.fulfilled,
        (state, action: PayloadAction<IPaperPaged>) => {
          state.papers = action.payload;
          state.loading = false;
        }
      )
      .addCase(getPapersByCommunityUrlKey.rejected, (state, action) => {
        state.error =
          action.error.message ||
          'Failed to fetch papers by community URL key.';
        state.loading = false;
      });
  }
});

export const papersReducer = papersSlice.reducer;
