import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getIsUserAdmin } from '../../../state/actions/userActions';
import { logout } from '../../../state/slices/userReducer';

export const useGetUserIsAdmin = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.login.user);

  useEffect(() => {
    if (user) {
      dispatch(getIsUserAdmin());
    } else {
      dispatch(logout());
    }
  }, [dispatch, user]);
};
