import { HubsApi } from '../../../api';

export const useGetHubsData = async (
  query: string,
  page: number,
  pageSize: number,
  type: number
) => {
  const hubsData = await HubsApi.getHubSearch(query, page, pageSize, type);
  return { hubsData };
};

// export const useGetTopicData = (urlKey: string) => {
//   const [topicData, setTopicData] = useState<ApiTopic | null>(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       const topic = await TopicApi.getTopic(urlKey);
//       setTopicData(topic);
//     };
//     fetchData();
//   }, [urlKey]);

//   return { topicData };
// };

//Need to remove this!!
// export const useGetCommunityData = (urlKey: string) => {
//   const [communityData, setCommunityData] =
//     useState<ApiCommunityResponse | null>(null);
//   useEffect(() => {
//     const fetchData = async () => {
//       const community = (await CommunityApi.getTopic(
//         urlKey
//       )) as ApiCommunityResponse;
//       setCommunityData(community);
//     };
//     fetchData();
//   }, [urlKey]);

//   return { communityData };
// };

// export const useGetTopicsData = (
//   page: number,
//   pageSize: number,
//   sortMethod?: TopicSortMethod
// ) => {
//   const [topicsData, setTopicsData] = useState<ApiTopicSummaryPaged | null>(
//     null
//   );

//   useEffect(() => {
//     const fetchData = async () => {
//       const topics = await TopicsApi.getAllTopics(page, pageSize, sortMethod);
//       setTopicsData(topics);
//     };
//     fetchData();
//   }, [page, pageSize, sortMethod]);

//   return { topicsData };
// };

// export const useGetCommunitiesData = (
//   page: number,
//   pageSize: number,
//   communityType: number,
//   sortMethod?: CommunitySortMethod
// ) => {
//   const [communitiesData, setCommunitiesData] =
//     useState<ApiCommunitySummaryPaged | null>(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       const communities = await CommunitiesApi.getAllCommunities(
//         page,
//         pageSize,
//         communityType,
//         sortMethod
//       );
//       setCommunitiesData(communities);
//     };
//     fetchData();
//   }, [page, pageSize, communityType, sortMethod]);

//   return { communitiesData };
// };

// export const useGetPapersByTopicUrlKeyData = (
//   page: number,
//   pageSize: number,
//   urlKey: string,
//   sortMethod?: PaperTopicSortMethod
// ) => {
//   const [papersState, setPapersState] = useState<{
//     papersData: ApiPaperPreview[] | null;
//     numPapersPages: number | null;
//   }>({
//     papersData: null,
//     numPapersPages: null
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       // Get all papers.
//       const papers = await TopicApi.getAllPaperByTopicUrlKey(
//         page,
//         pageSize,
//         urlKey,
//         sortMethod
//       );
//       if (papers && papers.page) {
//         // Map through all papers and get the comments and transform into ApiPaperPreview.
//         const paperPreviewList = await Promise.all(
//           papers.page.map(async (paper) => {
//             if (paper.commentSectionId) {
//               const comments = await CommentsectionsApi.getComments(
//                 paper.commentSectionId
//               );
//               return apiPaperToApiPaperPreview(paper, comments);
//             }
//             return apiPaperToApiPaperPreview(paper);
//           })
//         );
//         setPapersState({
//           papersData: paperPreviewList,
//           numPapersPages: papers.numPages || null
//         });
//       }
//     };
//     fetchData();
//   }, [page, pageSize, sortMethod, urlKey]);

//   return papersState;
// };

// export const useGetUserPostsByTopicUrlKeyData = (
//   page: number,
//   pageSize: number,
//   urlKey: string
// ) => {
//   const [userPostsState, setUserPostsState] = useState<{
//     discussionsData: ApiUserPostPreview[] | null;
//     numPostsPages: number | null;
//   }>({
//     discussionsData: null,
//     numPostsPages: null
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       const userPosts = await TopicApi.getAllPostsByTopicUrlKey(
//         page,
//         pageSize,
//         urlKey
//       );
//       if (userPosts && userPosts.page) {
//         // If the API returns the number of pages, set it here
//         const totalPages = userPosts.numPages || null;
//         // Map through all userPosts and get the comments and transform into ApiUserPostPreview.
//         const userPostsPreviewList = await Promise.all(
//           userPosts.page.map(async (userPost) => {
//             if (userPost.commentSectionId) {
//               const comments = await CommentsectionsApi.getComments(
//                 userPost.commentSectionId
//               );
//               return apiUserPostToUserPostPreview(userPost, comments);
//             }
//             return apiUserPostToUserPostPreview(userPost);
//           })
//         );

//         setUserPostsState({
//           discussionsData: userPostsPreviewList,
//           numPostsPages: totalPages
//         });
//       }
//     };
//     fetchData();
//   }, [page, pageSize, urlKey]);

//   return userPostsState;
// };

// export const useGetPapersByCommunityUrlKeyData = (
//   page: number,
//   pageSize: number,
//   urlKey: string,
//   sortMethod?: PaperCommunitySortMethod
// ) => {
//   const [papersState, setPapersState] = useState<{
//     papersData: ApiPaper[] | null;
//     numPapersPages: number | null;
//   }>({
//     papersData: null,
//     numPapersPages: null
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       const papers = await CommunityApi.getAllPaperBysUrlKey(
//         page,
//         pageSize,
//         urlKey,
//         sortMethod
//       );
//       if (papers && papers.page) {
//         const totalPages = papers.numPages || null;
//         // Map through all papers and get the comments and transform into ApiPaperPreview.
//         const paperPreviewList = await Promise.all(
//           papers.page.map(async (paper) => {
//             // if (paper.commentSectionId) {
//             //   const comments = await CommentsectionsApi.getComments(paper.commentSectionId);
//             //   return apiPaperToApiPaperPreview(paper, comments);
//             // }
//             return paper;
//           })
//         );
//         setPapersState({
//           papersData: paperPreviewList,
//           numPapersPages: totalPages
//         });
//       }
//     };
//     fetchData();
//   }, [page, pageSize, sortMethod, urlKey]);

//   return papersState;
// };

// export const useGetUserPostsByCommunityUrlKeyData = (
//   page: number,
//   pageSize: number,
//   urlKey: string
// ) => {
//   const [userPostsState, setUserPostsState] = useState<{
//     userPostsData: ApiUserPostPreview[] | null;
//     numUserPostsPages: number | null;
//   }>({
//     userPostsData: null,
//     numUserPostsPages: null
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       const userPosts = await CommunityApi.getAllPostsByUrlKey(
//         page,
//         pageSize,
//         urlKey
//       );
//       if (userPosts && userPosts.page) {
//         // If the API returns the number of pages, set it here
//         const totalPages = userPosts.numPages || null;
//         // Map through all user posts and get the comments and transform into ApiUserPostPreview.
//         const userPostsPreviewList = await Promise.all(
//           userPosts.page.map(async (userPost) => {
//             if (userPost.commentSectionId) {
//               const comments = await CommentsectionsApi.getComments(
//                 userPost.commentSectionId
//               );
//               return apiUserPostToUserPostPreview(userPost, comments);
//             }
//             return apiUserPostToUserPostPreview(userPost);
//           })
//         );

//         setUserPostsState({
//           userPostsData: userPostsPreviewList,
//           numUserPostsPages: totalPages
//         });
//       }
//     };
//     fetchData();
//   }, [page, pageSize, urlKey]);

//   return userPostsState;
// };

// export const useGetDiscussionsByTopicUrlKeyData = (
//   page: number,
//   pageSize: number,
//   urlKey: string,
//   sortMethod?: DiscussionSortMethod
// ) => {
//   const [discussionsState, setDiscussionsState] = useState<{
//     discussionsData: ApiDiscussionsPage[] | null;
//     numDiscussionPages: number | null;
//   }>({
//     discussionsData: null,
//     numDiscussionPages: null
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       const discussions = await TopicApi.getAllDiscussionsByTopicUrlKey(
//         page,
//         pageSize,
//         urlKey,
//         sortMethod
//       );
//       if (discussions && discussions.page) {
//         // If the API returns the number of pages, set it here
//         const totalPages = discussions.numPages || null;
//         // Map through all discussions and get the comments and transform into ApiDiscussionPage.
//         const discussionsList = await Promise.all(
//           discussions.page.map(async (discussion) => {
//             return {
//               type: discussion.type,
//               ...(discussion.type === 'paper' &&
//                 discussion.paper && {
//                   paper: discussion.paper
//                 }),
//               ...(discussion.type === 'post' &&
//                 discussion.userPost && {
//                   userPost: apiUserPostToUserPostPreview(
//                     discussion.userPost,
//                     discussion.userPost.commentSectionId &&
//                       discussion.userPost.commentSectionId !== 0
//                       ? await CommentsectionsApi.getComments(
//                           discussion.userPost.commentSectionId
//                         )
//                       : []
//                   )
//                 })
//             };
//           })
//         );

//         setDiscussionsState({
//           discussionsData: discussionsList,
//           numDiscussionPages: totalPages
//         });
//       }
//     };
//     fetchData();
//   }, [page, pageSize, urlKey, sortMethod]);

//   return discussionsState;
// };

// export const useGetDiscussionsByCommunityUrlKeyData = (
//   page: number,
//   pageSize: number,
//   urlKey: string,
//   sortMethod?: DiscussionSortMethod
// ) => {
//   const [discussionsState, setDiscussionsState] = useState<{
//     discussionsData: ApiDiscussionsPage[] | null;
//     numDiscussionPages: number | null;
//   }>({
//     discussionsData: null,
//     numDiscussionPages: null
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       const discussions = await CommunityApi.getAllDiscussionsByUrlKey(
//         page,
//         pageSize,
//         urlKey,
//         sortMethod
//       );
//       if (discussions && discussions.page) {
//         // If the API returns the number of pages, set it here
//         const totalPages = discussions.numPages || null;
//         // Map through all discussions and get the comments and transform into ApiDiscussionPage.
//         const discussionsList = await Promise.all(
//           discussions.page.map(async (discussion) => {
//             return {
//               type: discussion.type,
//               ...(discussion.type === 'paper' &&
//                 discussion.paper && {
//                   paper: discussion.paper
//                 }),
//               ...(discussion.type === 'post' &&
//                 discussion.userPost && {
//                   userPost: apiUserPostToUserPostPreview(
//                     discussion.userPost,
//                     discussion.userPost.commentSectionId &&
//                       discussion.userPost.commentSectionId !== 0
//                       ? await CommentsectionsApi.getComments(
//                           discussion.userPost.commentSectionId
//                         )
//                       : []
//                   )
//                 })
//             };
//           })
//         );

//         setDiscussionsState({
//           discussionsData: discussionsList,
//           numDiscussionPages: totalPages
//         });
//       }
//     };
//     fetchData();
//   }, [page, pageSize, urlKey, sortMethod]);

//   return discussionsState;
// };
