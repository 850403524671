import { FC } from 'react';
import { CreatePostPage } from './CreatePostPage';
import { UserPostApi } from '../../../api';
import { usePostForm } from '../hooks/usePostForm';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { ALLOWED_TAGS, ALLOWED_ATTR } from '../../../utils/constants';

interface Props {
  closeModal: () => void;
}

export const NewPostContainer: FC<Props> = (props) => {
  const {
    postName,
    markdown,
    selectedTopicTags,
    selectedCommunityTags,
    errors,
    inputTopicsValue,
    inputCommunityTags,
    topicTags,
    communityTags,
    onChangePostName,
    onChangeMarkdown,
    removeTopic,
    addTopic,
    removeCommunity,
    addCommunity,
    filterTopics,
    filterCommunities
  } = usePostForm();

  const navigate = useNavigate();

  const handleCreate = async () => {
    try {
      const topicUrlKeys = selectedTopicTags.map((tag) => tag.urlKey);
      const communityUrlKeys = selectedCommunityTags.map((tag) => tag.urlKey);
      const sanitizedMarkdown = DOMPurify.sanitize(markdown, {
        ALLOWED_TAGS: ALLOWED_TAGS,
        ALLOWED_ATTR: ALLOWED_ATTR
      });
      const response = await UserPostApi.create({
        title: postName,
        markdown: sanitizedMarkdown,
        topics: topicUrlKeys,
        communities: communityUrlKeys
      });
      const post = await response.json();
      if (Boolean(post)) {
        navigate(`/post/${post.id}`, { state: { post } });
      }
      props.closeModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return (
    <CreatePostPage
      topicTags={topicTags}
      communityTags={communityTags}
      selectedTopicTags={selectedTopicTags}
      selectedCommunityTags={selectedCommunityTags}
      removeTopic={removeTopic}
      filterTopics={filterTopics}
      addTopic={addTopic}
      addCommunity={addCommunity}
      removeCommunity={removeCommunity}
      filterCommunities={filterCommunities}
      setPostName={onChangePostName}
      postName={postName}
      setMarkdown={onChangeMarkdown}
      markdown={markdown}
      handleCreate={handleCreate}
      errors={errors}
      inputTopicsValue={inputTopicsValue}
      inputCommunityTags={inputCommunityTags}
    />
  );
};
