import { createAsyncThunk } from '@reduxjs/toolkit';
import { env } from '../../env';
import { ITopicSummaryPaged } from '../../types/topics.type';

export const getTopicsData = createAsyncThunk<
  ITopicSummaryPaged,
  { page: number; pageSize: number; sortMethod?: string }
>(
  'get/getTopicsData',
  async ({ page, pageSize, sortMethod }, { rejectWithValue }) => {
    const query =
      `&page=${page}&pageSize=${pageSize}` +
      (sortMethod ? `&order=${sortMethod}` : '');
    const url = `${env.apiUrl}/api/v1/topics?${query}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch topics');
      }

      const data: ITopicSummaryPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to fetch topics');
    }
  }
);

export const searchTopics = createAsyncThunk<
  ITopicSummaryPaged,
  { page: number; pageSize: number; query: string }
>(
  'get/searchTopics',
  async ({ page, pageSize, query }, { rejectWithValue }) => {
    const queryString = `q=${query}&p=${page}&s=${pageSize}`;
    const url = `${env.apiUrl}/api/v1/topics/search?${queryString}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to search topics');
      }

      const data: ITopicSummaryPaged = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue('Failed to search topics');
    }
  }
);
