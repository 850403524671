import { createAsyncThunk } from '@reduxjs/toolkit';
import { IApiCommunityResponse } from '../types/community.type';
import { env } from '../../env';

interface GetCommunityArgs {
  urlKey: string;
}

export interface UpdateCommunityAdminArgs {
  urlKey: string;
  data: {
    name: string;
    description: string;
    topicTags: string[];
  };
}

export interface UpdateCommunityCreatorArgs {
  urlKey: string;
  data: {
    description: string;
    topicTags: string[];
  };
}

interface DeleteCommunityArgs {
  urlKey: string;
}

export const getCommunity = createAsyncThunk(
  'get/getCommunity',
  async ({ urlKey }: GetCommunityArgs, { signal }) => {
    const response = await fetch(`${env.apiUrl}/api/v1/communities/${urlKey}`, {
      method: 'GET',
      signal
    });

    if (!response.ok) {
      throw new Error('community.get.errors.other');
    }

    const res = await response.json();

    return res as IApiCommunityResponse;
  }
);

export const updateCommunityByAdmin = createAsyncThunk(
  'put/updateCommunityByAdmin',
  async ({ urlKey, data }: UpdateCommunityAdminArgs, { signal }) => {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    const response = await fetch(
      `${env.apiUrl}/api/v1/communities/${urlKey}/by-super-user`,
      {
        method: 'PUT',
        signal,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.jwt}`
        }
      }
    );

    if (!response.ok) {
      throw new Error('Something went wrong with updating community');
    }

    const res = await response.json();

    return res;
  }
);

export const updateCommunityByCreator = createAsyncThunk(
  'put/updateCommunityByCreator',
  async ({ urlKey, data }: UpdateCommunityCreatorArgs, { signal }) => {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    const response = await fetch(
      `${env.apiUrl}/api/v1/communities/${urlKey}/by-creator`,
      {
        method: 'PUT',
        signal,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.jwt}`
        }
      }
    );

    if (!response.ok) {
      throw new Error('Something went wrong with updating community');
    }

    const res = await response.json();
    return res;
  }
);

export const deleteCommunity = createAsyncThunk(
  'delete/deleteCommunity',
  async ({ urlKey }: DeleteCommunityArgs, { signal }) => {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    const response = await fetch(
      `${env.apiUrl}/api/v1/communities/${urlKey}/hard`,
      {
        method: 'DELETE',
        signal,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.jwt}`
        }
      }
    );

    if (!response.ok) {
      throw new Error('Failed to delete the community');
    }

    return { urlKey };
  }
);
