import { createAsyncThunk } from '@reduxjs/toolkit';
import { IComment } from '../../types/comment.type';
import { ApiLoginResponse } from '../../api';
import { env } from '../../env';

// AsyncThunk for fetching comments without deleted comments
export const getComments = createAsyncThunk<
  IComment[],
  { commentSectionId: number; order?: string }
>(
  'get/getComments',
  async ({ commentSectionId, order }, { rejectWithValue }) => {
    try {
      const auth = JSON.parse(
        localStorage.getItem('auth') || ''
      ) as ApiLoginResponse;
      const response = await fetch(
        `${env.apiUrl}/api/v1/commentsections/${commentSectionId}?order=${order || ''}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.jwt}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch comments');
      }

      const data = await response.json();
      return data as IComment[];
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An error occurred while fetching the comments');
      }
    }
  }
);

// AsyncThunk for fetching comments with deleted(without nested) comments
export const getCommentsWithDeleted = createAsyncThunk<
  IComment[],
  { commentSectionId: number; order?: string }
>(
  'get/getCommentsWithDeleted',
  async ({ commentSectionId, order }, { rejectWithValue }) => {
    try {
      const authItem = localStorage.getItem('auth');
      const headers: Record<string, string> = {
        'Content-Type': 'application/json'
      };

      if (authItem) {
        const auth = JSON.parse(authItem) as ApiLoginResponse;
        if (auth.jwt) {
          headers['Authorization'] = `Bearer ${auth.jwt}`;
        }
      }

      const response = await fetch(
        `${env.apiUrl}/api/v1/commentsections/${commentSectionId}/with-deleted-marks?order=${order || ''}`,
        {
          method: 'GET',
          headers: headers
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch comments');
      }

      const data = await response.json();
      return data as IComment[];
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An error occurred while fetching the comments');
      }
    }
  }
);

// AsyncThunk for posting a new comment
export const postComment = createAsyncThunk<
  void,
  { commentSectionId: number; body: string; parentCommentId: number | null }
>(
  'post/postComment',
  async ({ commentSectionId, body, parentCommentId }, { rejectWithValue }) => {
    try {
      const auth = JSON.parse(
        localStorage.getItem('auth') || ''
      ) as ApiLoginResponse;

      const response = await fetch(
        `${env.apiUrl}/api/v1/commentsections/${commentSectionId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.jwt}`
          },
          body: JSON.stringify({
            body,
            parentCommentId
          })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to post comment');
      }
    } catch (error) {
      return rejectWithValue('Failed to post comment');
    }
  }
);

// AsyncThunk for editing an existing comment
export const editComment = createAsyncThunk<
  void,
  { commentId: number; body: string }
>('put/editComment', async ({ commentId, body }, { rejectWithValue }) => {
  try {
    const auth = JSON.parse(
      localStorage.getItem('auth') || ''
    ) as ApiLoginResponse;

    const response = await fetch(
      `${env.apiUrl}/api/v1/commentsections/comments/${commentId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.jwt}`
        },
        body: JSON.stringify({ body })
      }
    );

    if (!response.ok) {
      throw new Error('Failed to edit comment');
    }
  } catch (error) {
    return rejectWithValue('Failed to edit comment');
  }
});

// AsyncThunk for deleting an existing comment
export const deleteComment = createAsyncThunk<void, { commentId: number }>(
  'delete/deleteComment',
  async ({ commentId }, { rejectWithValue }) => {
    try {
      const auth = JSON.parse(
        localStorage.getItem('auth') || ''
      ) as ApiLoginResponse;

      const response = await fetch(
        `${env.apiUrl}/api/v1/commentsections/comments/${commentId}/soft`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${auth.jwt}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to delete comment');
      }
    } catch (error) {
      return rejectWithValue('Failed to delete comment');
    }
  }
);
