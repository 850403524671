import { ApiFilterData } from '../../api/oipub-api';
import { filterDataActions } from '../actions/filterDataActions';

interface FilterState {
  filterData: ApiFilterData;
}

const initialFilterData: ApiFilterData = {
  name: '',
  browse: '',
  order: '',
  pappersMin: null,
  pappersMax: null,
  postsMin: null,
  postsMax: null,
  startWith: ''
  // broadcasting: [],
  // discussionType: [],
  // discussionTitle: "",
  // commentsMin: null,
  // commentsMax: null,
  // creationBefore: null,
  // creationAfter: null,
  // creator: null,
  // topicComm: "",
};

const initialState: FilterState = {
  filterData: initialFilterData
};

const filterReducer = (
  state: FilterState = initialState,
  action: filterDataActions
): FilterState => {
  switch (action.type) {
    case 'SET_FILTER_DATA':
      return {
        ...state,
        filterData: action.payload
      };
    case 'RESET_FILTER_DATA':
      return {
        ...state,
        filterData: initialFilterData
      };
    default:
      return state;
  }
};

export default filterReducer;
