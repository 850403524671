import classNames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';

type AccordionItemProps = {
  title: string;
  isExpanded: boolean;
  onToggle: () => void;
  children: React.ReactNode;
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  isExpanded,
  onToggle,
  children
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (isExpanded) {
      const contentEl = contentRef.current as HTMLDivElement;
      setHeight(contentEl.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpanded]);

  return (
    <div className="accordion-item box-border px-6 pt-8 pb-6 mb-4">
      <button
        onClick={onToggle}
        className="accordion-title-wrap flex justify-between items-start"
      >
        <span className="accordion-title">{title}</span>
        <span
          className={classNames('accordion-toggle', {
            'accordion-toggle-active': isExpanded
          })}
        >
          {isExpanded ? '-' : '+'}
        </span>
      </button>
      <div
        ref={contentRef}
        className="accordion-content text-sm pt-2"
        style={{
          height: `${height}px`,
          overflow: 'hidden',
          transition: 'height 0.3s ease'
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default AccordionItem;
