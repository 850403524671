/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { NotFound } from './not-found';

import { Homepage } from './homepage';
import { ErrorPage } from './error';
import { PaperPage } from './paper';
import { CommunityContainer } from './community';
import { Feed } from './feed';
import { Search } from './search';
import { UserPostPage } from './post';
import { ReferralPageContainer, InvitePage } from './referral';
import { AboutPage, ContactPage, TermsAndConditionsPage } from './static-pages';
import { TopicContainer } from './topic-v2';
import { TopicPapersPage } from './topic/topic-papers-page';
import { TopicPostsPage } from './topic/topic-posts-page';
import { Root } from './root';
import { ResetPasswordPageContainer } from './reset-password/ResetPasswordPageContainer';

interface Props {}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Homepage />
      },
      {
        path: 'about',
        element: <AboutPage />
      },
      {
        path: '/topics',
        element: <Homepage />,
        children: [
          {
            path: '/topics/:page',
            element: <Homepage />
          }
        ]
      },
      {
        path: '/communities',
        element: <Homepage />,
        children: [
          {
            path: '/communities/:page',
            element: <Homepage />
          }
        ]
      },
      {
        path: '/paper',
        children: [
          {
            path: '/paper/:yearMonth/:slug/:hash',
            element: <PaperPage />
          },
          {
            path: '/paper/:id',
            element: <PaperPage />
          },
          {
            path: '/paper/:yearMonth/:slug/:hash',
            element: <PaperPage />
          },
          {
            path: '/paper/:id',
            element: <PaperPage />
          }
        ]
      },
      {
        path: '/community',
        children: [
          {
            path: '/community/:urlKey',
            element: <CommunityContainer />
          },
          {
            path: '/community/:urlKey/papers',
            element: <CommunityContainer /> //PapersCommunityList
          },
          {
            path: '/community/:urlKey/discussions',
            element: <CommunityContainer /> // PapersTopicList
          }
        ]
      },
      {
        path: '/topic',
        children: [
          {
            path: '/topic/:urlKey',
            element: <TopicContainer />
          },
          {
            path: '/topic/:urlKey/papers',
            element: <TopicContainer />
          },
          {
            path: '/topic/:urlKey/discussions',
            element: <TopicContainer />
          }
        ]
      },
      {
        path: '/post/:id',
        element: <UserPostPage />
      },
      {
        path: '/referral',
        element: <ReferralPageContainer />
      },
      {
        path: '/invite',
        element: <InvitePage />
      },
      {
        path: '/search',
        element: <Search />
      },
      {
        path: '/feed',
        element: <Feed />
      },
      {
        path: '/contact',
        element: <ContactPage />
      },
      {
        path: '/terms-and-conditions',
        element: <TermsAndConditionsPage />
      },
      {
        path: '/topic/:name/papers',
        element: <TopicPapersPage />
      },
      {
        path: '/topic/:name/papers/:page',
        element: <TopicPapersPage />
      },
      {
        path: '/topic/:name/posts',
        element: <TopicPostsPage />
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPageContainer />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]);

export const AppRoutes: FC<Props> = () => {
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
};

/* <Route
     path="/"
    render={({ location }) => {
      const loc = location.pathname + location.search;
      analytics.page(loc);
      return null;
    }}
  />
  <Switch>
    {RouteList.map(({ exact, ...rest }, idx) => (
      <Route key={idx} exact={exact} {...rest} />
    ))}
    <Route component={NotFound} />
  </Switch> */
// {
//   path: "/account",
//   component: withAuth(AccountPage),
// },
// {
//   path: "/create-community",
//   component: CreateCommunity,
// }
// {
//   path: "/topics-communities",
//   component: TopicsAndCommunitiesPage,
//   exact: true,
// },
// {
//   path: "/topics",
//   component: TopicsPage,
//   exact: true,
// },
// {
//   path: "/post/new",
//   component: NewPostContainer,
//   exact: true,
// },
// {
//   path: "/login",
//   component: Login,
//   exact: true,
// },
// {
//   path: "/register",
//   component: Register,
//   exact: true,
// },
// {
//   path: "/reset-password",
//   component: ResetPasswordPage,
//   exact: true,
// }
