import { Spinner } from '../../svgs';

export interface Props {
  text?: string;
}

export const Loading: React.FC<Props> = ({ text }: Props) => {
  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center">
      <div className="flex items-center">
        <div className="animate-spin w-6 h-6 text-gray-600 transition-transform duration-700">
          <Spinner />
        </div>
        {text && <span className="ml-2 text-sm text-gray-500"> {text}</span>}
      </div>
    </div>
  );
};
