import oipubApi from '../api-instance';
import { ApiLoginResponse } from '../oipub-api';

export const UserApi = {
  async myProfileDetail() {
    const auth = JSON.parse(
      localStorage.getItem('auth') || '{}'
    ) as ApiLoginResponse;
    if (auth && auth.jwt) {
      const response = await oipubApi.v1MyProfile({
        headers: { Authorization: `Bearer ${auth.jwt}` }
      });
      return response;
    } else {
      throw new Error('Authorization failed');
    }
  }
};
