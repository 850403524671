import { env } from '../../env';
import oipubApi from '../api-instance';
import { ApiCommunitySummaryPaged, ApiLoginResponse } from '../oipub-api';

export const CommunitiesApi = {
  async getAllCommunities(
    page: number,
    pageSize: number,
    communityType: number,
    order?: string
  ): Promise<ApiCommunitySummaryPaged> {
    const response = await oipubApi.v1CommunitiesList({
      communityType,
      page,
      pageSize,
      order
    });
    return response.data;
  },

  async createCommunity(
    name?: string,
    description?: string,
    topicTags?: string[]
  ) {
    const auth = JSON.parse(
      localStorage.getItem('auth') || ''
    ) as ApiLoginResponse;
    const response = await oipubApi.v1CommunitiesCreate(
      { name, description, topicTags },
      {
        headers: {
          Authorization: `Bearer ${auth.jwt}`
        }
      }
    );
    return response;
  },

  // async updateCommunityByAdmin({
  //   urlKey,
  //   name,
  //   description,
  //   topicTags
  // }: UpdateCommunityParams): Promise<string> {
  //   const payload = {
  //     name,
  //     description,
  //     topicTags
  //   };
  //   const auth = JSON.parse(localStorage.getItem('auth') || '{}');
  //   const response = await fetch(
  //     `${process.env.REACT_APP_API_URL}/api/v1/communities/${urlKey}/by-super-user`,
  //     {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${auth.jwt}`
  //       },
  //       body: JSON.stringify(payload)
  //     }
  //   );

  //   if (!response.ok) {
  //     throw new Error('Failed to update community by admin');
  //   }
  //   return response.json();
  // },

  // async updateCommunityByCreator({
  //   urlKey,
  //   name,
  //   description,
  //   topicTags
  // }: UpdateCommunityParams) {
  //   const payload = {
  //     name,
  //     description,
  //     topicTags
  //   };
  //   const auth = JSON.parse(localStorage.getItem('auth') || '{}');
  //   const response = await fetch(
  //     `${process.env.REACT_APP_API_URL}/api/v1/communities/${urlKey}/by-creator`,
  //     {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${auth.jwt}`
  //       },
  //       body: JSON.stringify(payload)
  //     }
  //   );

  //   if (!response.ok) {
  //     throw new Error('Failed to update community by creator');
  //   }
  //   return response.json();
  // },

  async updateCommunityName(urlKey: string, name: string) {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    try {
      const response = await fetch(
        `${env.apiUrl}/api/v1/communities/${urlKey}/name`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.jwt}`
          },
          body: JSON.stringify({ name })
        }
      );
      if (!response.ok) {
        throw new Error('Failed to update community name');
      }
      const data = await response.json();
      return data.newUrlKey;
    } catch (error) {
      throw error;
    }
  },

  async updateCommunityDescription(urlKey: string, description: string) {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    try {
      const response = await fetch(
        `${env.apiUrl}/api/v1/communities/${urlKey}/description`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.jwt}`
          },
          body: JSON.stringify({ description })
        }
      );
      if (!response.ok) {
        throw new Error('Failed to update community description');
      }
      return response;
    } catch (error) {
      throw error;
    }
  },

  async updateCommunityTopics(urlKey: string, topicTags: string[]) {
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    try {
      const response = await fetch(
        `${env.apiUrl}/api/v1/communities/${urlKey}/topic-tags`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.jwt}`
          },
          body: JSON.stringify({ topicTags })
        }
      );
      if (!response.ok) {
        throw new Error('Failed to update community topics');
      }
      return response;
    } catch (error) {
      throw error;
    }
  },

  async deleteCommunity(urlKey: string) {
    const auth = JSON.parse(
      localStorage.getItem('auth') || ''
    ) as ApiLoginResponse;
    const response = await oipubApi.v1CommunitiesDelete(urlKey, {
      headers: {
        Authorization: `Bearer ${auth.jwt}`
      }
    });
    return response;
  },

  async searchCommunities(
    page: number,
    pageSize: number,
    query: string
  ): Promise<ApiCommunitySummaryPaged> {
    const queryParams = {
      q: query,
      p: page,
      s: pageSize
    };
    const response = await oipubApi.v1SearchCommunities(queryParams);
    return response.data;
  }
};
