import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { ApiSearchType } from '../../api';
import { ApiDatabaseCounts } from '../../api/oipub-api';
import { Search } from '../../svgs';
import { texts } from '../../text';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
interface Props {
  initialText?: string;
  initialType?: ApiSearchType;
  onSearch(term: string, type: ApiSearchType): void;
  counts?: ApiDatabaseCounts;
}

export const SearchField3 = ({
  initialText = '',
  initialType = ApiSearchType.Papers,
  onSearch = () => {},
  counts
}: Props) => {
  const [text, setText] = useState(initialText);
  const [type, setType] = useState(initialType);
  const [placeholder, setPlaceholder] = useState(texts.searchFieldPlaceHolder);
  const location = useLocation();
  const queryString = qs.parse(location.search.substring(1));
  const query = queryString['q'] as string | '';
  const FilterSchema = Yup.object().shape({
    type: Yup.string().nullable(),
    search: Yup.string().nullable()
  });

  const formatNumber = (number: number) =>
    new Intl.NumberFormat().format(number);

  const initialValues = {
    type: 'papers',
    search: query || ''
  };

  useEffect(() => {
    setText(initialText);
  }, [initialText]);

  useEffect(() => {
    if (type === ApiSearchType.Papers && counts?.numPapers)
      return setPlaceholder(`Search ${formatNumber(counts.numPapers)} papers`);
    if (type === ApiSearchType.Topics && counts?.numTopics)
      return setPlaceholder(`Search ${formatNumber(counts.numTopics)} topics`);
    if (type === ApiSearchType.Communities && counts?.numCommunities)
      return setPlaceholder(
        `Search ${formatNumber(counts.numCommunities)} communities`
      );
    setPlaceholder(texts.searchFieldPlaceHolder);
  }, [type, counts?.numPapers, counts?.numTopics, counts?.numCommunities]);

  const onChangeDropDown: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const newSearchType = event.target.value;
    if (newSearchType) setType(newSearchType as ApiSearchType);
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(text, type);
    }
  };

  return (
    <div
      className={classNames(
        'flex h-12 w-full max-w-3xl text-sm bg-whiteBorder  p-1 rounded gap-3 border-2 main-search border-transparent'
      )}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={FilterSchema}
        onSubmit={(values) => {
          onSearch(values.search || query, type);
        }}
      >
        {({ setFieldValue }) => (
          <Form className="w-full flex">
            <Field
              as="select"
              name="type"
              value={type}
              className="rounded border border-primaryDefault tablet:pl-4 tablet:pr-6 pr-2 pl-2 py-2 text-primaryDefault focus:outline-none"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                setFieldValue('type', e.target.value);
                onChangeDropDown(e);
              }}
            >
              <option value={ApiSearchType.Papers}>
                {ApiSearchType.Papers}
              </option>
              <option value={ApiSearchType.Topics}>
                {ApiSearchType.Topics}
              </option>
              <option value={ApiSearchType.Communities}>
                {ApiSearchType.Communities}
              </option>
            </Field>
            <Field
              className="flex-grow pl-3 inline-block focus:outline-none bg-transparent placeholder-primaryDark focus:placeholder-grayBorder placeholder-grayBorder w-full"
              type="text"
              name="search"
              placeholder={placeholder}
              onKeyDown={handleKeydown}
            />
            <button
              type="submit"
              className="flex items-center bg-gradient-to-r from-primaryLight to-primaryDefault p-3 tablet:px-10 px-4  gap-1 rounded opacity-100 hover:opacity-50 focus:outline-none"
            >
              <span className="text-white">{texts.search}</span>
              <div className="w-4 text-white">
                <Search />
              </div>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
