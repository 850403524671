import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ApiUserPost } from '../../api/oipub-api';

interface Props {
  post: ApiUserPost;
}

export const UserPostPreview: FC<Props> = ({ post }) => {
  const url = `/post/${post.id}`;

  return (
    <Link
      className="border-b-gray-400 last:border-b-0 hover:bg-primary-100 block w-full max-w-3xl mx-auto transition-colors duration-200 border-b"
      to={url}
    >
      <div className="tablet:pt-8 tablet:px-4 px-2 pt-6 pb-4">
        <div className="mb-4">
          <h3 className="tablet:text-xl text-base font-bold leading-tight">
            {post.title}
          </h3>
        </div>
      </div>
    </Link>
  );
};
