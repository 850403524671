import React, { useCallback, useState } from 'react';
import { useAppSelector } from '../../state';
import { Button, Textarea } from '../form';
import { Pencil } from '../../svgs';
import { analytics } from '../../analytics';
import { IComment } from '../../types/comment.type';
import { DiscussionType, Comment, CountCommentsOrder } from '.';
import { useNavigate } from 'react-router-dom';

export interface Props {
  type: DiscussionType;
  comments: IComment[] | null;
  onReply(text: string, parentId: number | null): Promise<void>;
  onEdit(commentId: number, body: string): Promise<void>;
  onDelete(commentId: number): Promise<void>;
}

export const Discussion: React.FC<Props> = ({
  type,
  comments,
  onReply,
  onEdit,
  onDelete
}) => {
  const user = useAppSelector((s) => s.login.user);
  const [commentExpanded, setCommentExpanded] = useState(false);
  const [text, setText] = useState('');
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();

  const handleOpenModalHash = useCallback(
    (modalName: string) => {
      const currentSearch = location.search;
      navigate(`${currentSearch}#${modalName}`, { replace: true });
    },
    [navigate]
  );

  const openSignupModal = useCallback(
    () => handleOpenModalHash('signup'),
    [handleOpenModalHash]
  );

  const handleReply = async () => {
    setSaving(true);
    try {
      analytics.events.addComment(type);
      await onReply(text, null);
      setText('');
      setCommentExpanded(false);
    } finally {
      setSaving(false);
    }
  };
  return (
    <div className="tablet:px-12 px-6 max-w-3xl top-bar-border-top">
      <div className="tablet:mt-6 max-w-3xl mt-3">
        <div
          className={`flex items-start justify-between ${!commentExpanded ? 'mb-4' : 'mb-2'} `}
        >
          {user ? (
            !commentExpanded ? (
              <div className="flex justify-between w-full laptop:items-center items-start laptop:flex-row flex-col">
                <Button
                  outline={false}
                  onClick={() => setCommentExpanded(true)}
                  className="py-2 px-6 mb-2 laptop:mb-0"
                >
                  <span className="inline-block w-4 h-4 mr-1">
                    <Pencil />
                  </span>
                  <span>Add comment</span>
                </Button>
                <CountCommentsOrder comments={comments} />
              </div>
            ) : (
              ''
            )
          ) : (
            <Button outline={true} onClick={openSignupModal}>
              <span className="inline-block w-4 h-4 mr-1">
                <Pencil />
              </span>
              <span>Sign up to join the discussion</span>
            </Button>
          )}
        </div>
        {commentExpanded && (
          <div className="  mb-4">
            <Textarea
              className="text-sm mb-4"
              disabled={saving}
              value={text}
              onChange={(e) => setText(e.target.value)}
              minRows={4}
              placeholder="Type your comment here"
            />
            <div className="flex justify-between items-start laptop:flex-row flex-col">
              <div className="flex mb-1">
                <Button
                  outline={true}
                  disabled={saving}
                  onClick={() => setCommentExpanded(false)}
                  className="py-2 tablet:px-12 px-6"
                >
                  Cancel
                </Button>
                <Button
                  disabled={saving}
                  onClick={handleReply}
                  className="py-2 tablet:px-12 px-6 ml-2"
                >
                  Submit
                </Button>
              </div>
              <CountCommentsOrder comments={comments} />
            </div>
          </div>
        )}

        {comments === null || comments.length === 0 ? (
          <p className="text-sm text-gray-600 mb-4">
            The discussion has not yet been started.
          </p>
        ) : (
          <div className="px-4 bg-white">
            <div className="px-8 py-6 pb-1">
              {comments.map((comment) => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  type={type}
                  onReply={onReply}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
