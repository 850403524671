import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiLoginResponse } from '../../api';
import { env } from '../../env';

export const getIsUserAdmin = createAsyncThunk(
  'get/getIsUserAdmin',
  async (_, { rejectWithValue }) => {
    const auth = JSON.parse(
      localStorage.getItem('auth') || '{}'
    ) as ApiLoginResponse;
    if (!auth) {
      return rejectWithValue('No user logged in');
    }
    try {
      const response = await fetch(`${env.apiUrl}/api/v1/myprofile`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.jwt}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user roles');
      }
      const data: IUserProfileIsAdmin = await response.json();
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        return rejectWithValue('An unknown error occurred');
      }
    }
  }
);
